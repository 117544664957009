import classNames from "classnames/bind";
import styles from "./ItemSearch.module.scss";
import gt1 from "../../../../assets/images/Course/gt1.png";
const cx = classNames.bind(styles);
function ItemSearch({ title, link, data, infor }) {
  return (
    <div className={cx("wrapper")}>
      {infor || (
        <div className={cx("title-head")}>
          <h5>{title}</h5>
          <a href={link}>Xem thêm</a>
        </div>
      )}
      <div className={cx("item")}>
        {data?.map((item) => {
          return (
            <a href={item.path} className={cx("link")}>
              <div>
                <img src={item.image || gt1}></img>
              </div>
              <span>{item.name}</span>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default ItemSearch;
