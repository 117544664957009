import classNames from "classnames/bind";
import styles from "./Subject.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleCheck,
  faMinus,
  faPlug,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SubjectItem from "./SubjecItem/SubjectItem";
const cx = classNames.bind(styles);
function Subject({
  name,
  weight,
  active,
  path,
  free,
  fee,
  data,
  pt5,
  aadNote,
  onclick,
  handleActive,
}) {
  const [status, setStatus] = useState(false);

  return (
    <div className={cx("container")}>
      <a
        target="_blank"
        href={path}
        onClick={() => {
          setStatus((state) => {
            return !state;
          });
        }}
        className={cx("wrapper", { active: active })}
      >
        <div>
          <FontAwesomeIcon
            className={cx("icon")}
            icon={faPlus}
          ></FontAwesomeIcon>
          <span>
            {name || "Tài liệu khó kiếm quá ít bữa nữa cập nhật nhen...🤣"}
          </span>
        </div>
        <span>{weight}</span>
      </a>
      {data && status && (
        <div className={cx("subject-item")}>
          {data.map((item) => {
            const str = item.name;
            const Name =
              str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            return (
              <SubjectItem
                onclick={(data) => {
                  aadNote();
                  onclick(data);
                }}
                handleActive={handleActive}
                pt5
                path={item.path}
                name={Name}
                fee={fee}
                price={item.price}
              ></SubjectItem>
            );
          })}
          <SubjectItem
            onclick={onclick}
            handleActive={handleActive}
            pt5
            name={pt5 ? "Xem chương trình đào tạo" : "Các đề tài khác..."}
            text={"Xem"}
          ></SubjectItem>
        </div>
      )}
    </div>
  );
}

export default Subject;
