import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookQuran,
  faCalculator,
  faHouse,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Slidebar.module.scss";
import MenuItem from "./MenuItem/MenuItem";
const cx = classNames.bind(styles);
function Slidebar() {
  return (
    <nav className={cx("wrapper")}>
      <MenuItem
        to={"/"}
        title={"Bách khoa"}
        icon={
          <FontAwesomeIcon
            fontSize={"1.8rem"}
            color="#404041"
            icon={faHouse}
          ></FontAwesomeIcon>
        }
      ></MenuItem>
      <MenuItem
        to={"/document"}
        title={"Tài liệu"}
        icon={
          <FontAwesomeIcon
            fontSize={"1.8rem"}
            color="#404041"
            icon={faBookQuran}
          ></FontAwesomeIcon>
        }
      ></MenuItem>
      <MenuItem
        to={"/assignment"}
        title={"Bài tập lớn"}
        icon={
          <FontAwesomeIcon
            fontSize={"1.8rem"}
            color="#404041"
            icon={faStar}
          ></FontAwesomeIcon>
        }
      ></MenuItem>
      <MenuItem
        to={"/pt5"}
        title={"Tính điểm"}
        icon={
          <FontAwesomeIcon
            fontSize={"1.8rem"}
            color="#404041"
            icon={faCalculator}
          ></FontAwesomeIcon>
        }
      ></MenuItem>
    </nav>
  );
}
export default Slidebar;
