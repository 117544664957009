import classNames from "classnames/bind";
import styles from "./Course.module.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import {
  faCameraRetro,
  faCheck,
  faCirclePlay,
  faMinus,
  faTimeline,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faFacebook, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { useParams } from "react-router-dom";
import hot from "../../../assets/images/hot.gif";
import Calculus1 from "../../../assets/images/Course/gt1.png";
import PPT from "../../../assets/images/Course/ppt.png";
import calculus2 from "../../../assets/images/Course/gt2.png";
import chemistry from "../../../assets/images/Course/hdc.png";
import physic1 from "../../../assets/images/Course/vl1.png";
import physic2 from "../../../assets/images/Course/vl2.png";
import xstk from "../../../assets/images/Course/xacsuatthongke.png";
import dstt from "../../../assets/images/Course/dstt.png";
import matlab from "../../../assets/images/BTL/matlab.png";
import sach from "../../../assets/images/BTL/sach.png";
import sachppt from "../../../assets/images/BTL/sachppt.png";
import iconHot from "../../../assets/images/hot.gif";
//
import btl_xstk from "../../../assets/images/BTL/btl_xstk.png";
import btl_vl1 from "../../../assets/images/BTL/btl_vl1.png";
import btl_vl2 from "../../../assets/images/BTL/btl_vl2.png";
import all from "../../../assets/images/BTL/all.png";
import Subject from "../../Elements/Subject/Subject";
import Button from "../../Elements/Button/Button";

const cx = classNames.bind(styles);

function Course() {
  const QuocVuong = {
    name: "Nguyễn Quốc Vương",
    intro:
      "Người dạy là mình nhé, Nguyễn Quốc Vương, sinh viên khóa K19 tốt  nghiệp loại giỏi tháng 6/2023, hiện tại đang học Thạc Sĩ ở trường Bách Khoa HCM, mình đã dạy từ năm 2019 mỗi khóa cả ngàn sinh viên nên các bạn yên tâm về chất lượng nhé.",
    linkin: "https://www.facebook.com/profile.php?id=100086390823801",
  };
  const NgocLong = {
    name: "Ngọc Long",
    intro:
      "Người dạy là anh Ngọc Long, sinh viên khóa K18 tốt  nghiệp năm 2024, hiện tại đang làm Research and Development Enginee tại công ty Juki , mình đã dạy từ năm 2018 mỗi kì đều cả vài trăm bạn học mình nên các bạn yên tâm về chất lượng nhé.",
    linkin: "https://www.facebook.com/profile.php?id=100007913497459",
  };
  const DataCourse = [
    {
      id: "gt1",
      name: "Lớp Giải Tích 1 K24",
      img: Calculus1,
      method:
        "Học Livestream 2 buổi/tuần trong group fb kín, học toàn bộ từ lý thuyết làm bài tập luyện đề thi thử (có thể thay thế việc học trên trên). Có video, tài liệu K23 để học trước nếu muốn, record cho mỗi buổi học. Đăng kí sớm tặng khóa lập trình matlab và bài tập lớn các môn đại cương.",
      intro:
        "https://www.youtube.com/watch?v=O7WrQoLtoEw&list=PL8RXTGoT1e8NgMpj5DiqBpw6rW-1AFEwB",
      tutor: QuocVuong,
      benefits: [
        "Dạy livestream group facebook kín.",
        "Thứ 3, thứ 6 lúc 19h30 trong 4 tháng",
        "Có video k22, tài liệu để các bạn học vượt.",
      ],
      scheduleMiddle: [
        { content: "Buổi 1: Lim và phương pháp tính lim", time: "26/8/2024" },
        { content: "Buổi 2: Vô cùng bé", time: "28/8/2024" },
        { content: "Buổi 3: Vô cùng lớn, Taylor-Malaurin", time: "30/8/2024" },
        {
          content: "Buổi 4: Các hàm mới, tập giá trị, tập xác định, hàm ngược",
          time: "12/9/2024",
        },
        {
          content: "Buổi 5: Hàm liên tụi, đạo hàm cấp 1, hàm hợp",
          time: "16/9/2024",
        },
        { content: "Buổi 6:  Ứng dụng đạo hàm phần 1", time: "29/9/2024" },
        { content: "Buổi 7:  Ứng dụng đạo hàm P2", time: "03/10/2024" },
        { content: "Buổi 8:  Vi phân", time: "06/10/2024" },
        { content: "Buổi 9:  Tiệm cận f(x)", time: "9/10/2024" },
        { content: "Buổi 10:  Min max", time: "13/10/2024" },
        {
          content: "Buổi 11:  Tiệm cận phương trinh tham số",
          time: "16/10/2024",
        },
        {
          content: "Buổi 12:  Đạo hàm và ý nghĩa hàm tham số",
          time: "19/10/2024",
        },
        { content: "Buổi 13:  Giải đề minh họa", time: "23/10/2024" },
        { content: "Buổi 14:  Giải đề thi thử)", time: "26/10/2024" },
        { content: "Buổi hỏi đáp: Hỏi đáp trước thi", time: "30/10/2024" },
      ],
      scheduleFinal: [
        { content: "Buổi 15: Ông tập nguyên hàm tích phân", time: "Chưa có" },
        { content: "Buổi 16:  Ứng dụng tích phân xác định", time: "Chưa có" },
        { content: "Buổi 17:  Tích phân suy rộng", time: "Chưa có" },
        { content: "Buổi 18:  Ôn tập tích phân", time: "Chưa có" },
        { content: "Buổi 19:  Phương trình vi phân cấp 1", time: "Chưa có" },
        {
          content: "Buổi 20:  Ứng dụng phương trình vi phân",
          time: "Chưa có",
        },
        { content: "Buổi 21:  Luyện tập vi phân cấp 1", time: "Chưa có" },
        { content: "Buổi 22:  Phương trình vi phân cấp 2", time: "Chưa có" },
        { content: "Buổi 23:  Hệ phương trình vi phân", time: "Chưa có" },
        { content: "Buổi 24:  Giải đề minh họa 1", time: "Chưa có" },
        { content: "Buổi 25:  Giải đề minh họa 2", time: "Chưa có" },
      ],
      price: "Đứng đầu Bách Khoa",
      oldPrice: "500k",
      assignment: {
        img: all,
        title:
          "Tặng bộ tài liệu BTL anh mua gồm các môn đại cương như giải tích 1, đại số tuyến tính, thí nghiệm hóa đc, báo cáo TNVL,...(không áp dụng cho lớp cuối kì)",
      },
    },
    {
      id: "gt2",
      name: "Lớp Giải Tích 2 K24",
      img: calculus2,
      intro:
        "https://www.youtube.com/watch?v=X5bQszvhz5U&list=PL8RXTGoT1e8OtUFtC-_33z7QUp9fPpPDg&index=3",
      tutor: QuocVuong,
      benefits: [
        "Dạy livestream group facebook kín.",
        "Thứ 3, thứ 6 lúc 19h30 trong 4 tháng",
        "Có video k22, tài liệu để các bạn học vượt.",
      ],
      method:
        "Học Livestream 2 buổi/tuần trong group fb kín, học toàn bộ từ lý thuyết làm bài tập luyện đề thi thử (có thể thay thế việc học trên trên). Có video, tài liệu K23 để học trước nếu muốn, record cho mỗi buổi học. Đăng kí sớm tặng khóa lập trình matlab và bài tập lớn các môn đại cương.",
      scheduleMiddle: [
        { content: "Buổi 1: Tích phân kép", time: "15/2/2024" },
        { content: "Buổi 2: Không thi -Tọa độ cực", time: "15/2/2024" },
        {
          content: "Buổi 3: Không thi - Ứng dụng trong tích phân kép",
          time: "15/2/2024",
        },
        { content: "Buổi 4: Tập xác định, miền giá trị", time: "15/2/2024" },
        { content: "Buổi 4: Đạo hàm và đạo hàm hàm hợp", time: "15/2/2024" },
        { content: "Buổi 5: Hàm ẩn và vi phân", time: "15/2/2024" },
        {
          content: "Buổi 6: Đạo hàm theo hướng và đường mức",
          time: "15/2/2024",
        },
        { content: "Buổi 7: Cực trị tự do - Min - Max", time: "15/2/2024" },
        { content: "Buổi 9: Min max dùng nhân tử Larange", time: "15/2/2024" },
        { content: "Buổi 10: Giải đề minh họa", time: "15/2/2024" },
        { content: "Buổi 11: Thi thử và giải đề thi thử", time: "15/2/2024" },
        { content: "Buổi 12: Hỏi đáp trước khi thi", time: "15/2/2024" },
      ],
      scheduleFinal: [
        { content: "Buổi 13: Tích phân bội 3 và ứng dụng", time: "15/2/2024" },
        {
          content: "Buổi 14: Tích phân đường loại 1 và ứng dụng",
          time: "15/2/2024",
        },
        {
          content:
            "Buổi 15:Tích phân đường loại 2, công thức Green và ứng dụng",
          time: "15/2/2024",
        },
        {
          content: "Buổi 16: Tích phân mặt loại 1 và ứng dụng",
          time: "15/2/2024",
        },
        { content: "Buổi 17: Tích phân mặt lọa 2 phần 1", time: "15/2/2024" },
        { content: "Buổi 18: Tích phân mặt loại 2 phần 2", time: "15/2/2024" },
        {
          content: "Buổi 19: Xét phân kì, hội tụ của chuỗi số",
          time: "15/2/2024",
        },
        {
          content: "Buổi 20: Bán kính hội tụ và miền hội tụ",
          time: "15/2/2024",
        },
        { content: "Buổi 21: Tổng chuỗi", time: "15/2/2024" },
        {
          content: "Buổi 22: Tọa độ cầu và trụ trong tích phân bội 3",
          time: "15/2/2024",
        },
        { content: "Buổi 23: Giải đề thi cuối kì HK221", time: "15/2/2024" },
        {
          content: "Buổi 24: Giải đề thi minh họa thầy Phương học kì 222",
          time: "15/2/2024",
        },
        { content: "Buổi 25: Giải đề minh họa HK 222", time: "15/2/2024" },
        { content: "Buổi 26: Hỏi đáp trước khi thi", time: "15/2/2024" },
      ],
      assignment: {
        img: all,
        title:
          "Tặng bộ tài liệu BTL anh mua gồm các môn đại cương như giải tích, đại số tuyến tính, hóa, báo cáo TNVL,...",
      },
      price: "250K/Cả Kì",
      oldPrice: "400k",
    },
    {
      id: "vl1",
      name: "Lớp  vật lý 1 K24",
      intro:
        "https://www.youtube.com/watch?v=V0Ro5QL9maU&list=PL8RXTGoT1e8ObkrDPpv9D9ZwwYmHuguPL",
      img: physic1,
      tutor: NgocLong,
      benefits: [
        "Dạy qua video .",
        "Kiến thức không đổi qua các kì",
        "Tài liệu để các bạn học vượt.",
      ],
      method:
        "Học qua video, học toàn bộ từ lý thuyết làm bài tập trong sách bài tập(có thể thay thế việc học trên trên). Có video, tài liệu K23 để học trước nếu muốn, tặng bài tập lớn vật lý 1 và khóa học matlab ",
      scheduleMiddle: [
        {
          content: "Buổi 1: Chương 1_Chuyển động thẳng đều, thẳng biến đổi đều",
          time: "video",
        },
        { content: "Buổi 2: Chương 1_Ném xiên", time: "video" },
        {
          content: "Buổi 3: Chương 1_Chuyển động tròn, cộng vận tốc",
          time: "video",
        },
        { content: "Buổi 4: Chương 2_Các định luật Newton", time: "video" },
        {
          content: "Buổi 5: Chương 2_Bài tập định luật Newton, lực quán tính",
          time: "video",
        },
        { content: "Buổi 6: Chương 2_Năng lượng, động lượng", time: "video" },
        {
          content: "Buổi 7: Chương 3_Khối tâm, momen quán tính",
          time: "video",
        },
        {
          content: "Buổi 8: Chương 3_Momen lực, momen động lượng, va chạm",
          time: "video",
        },
        { content: "Buổi 9: Ôn tập chương 1, 2", time: "video" },
        { content: "Buổi 10: Ôn tập chương 3", time: "video" },
      ],
      scheduleFinal: [
        {
          content:
            "Buổi 11: Chương 4_Phương trình trạng thái khí, các đẳng quá trình",
          time: "video",
        },
        {
          content: "Buổi 12: Chương 4 + Chương 5_Nội năng, nguyên lý 1",
          time: "video",
        },
        { content: "Buổi 13: Chương 5_Máy nhiệt, entropy", time: "video" },
        {
          content: "Buổi 14: Chương 6_Điện tích điểm, lưỡng cực điện",
          time: "video",
        },
        { content: "Buổi 15: Đại số tuyến tính", time: "video" },
        { content: "Buổi 16: Chương 6_Điện thế, gauss", time: "video" },
        {
          content:
            "Buổi 17: Chương 6 + Chương 7_Bài tập điện thế, gauss, vật dẫn",
          time: "video",
        },
        { content: "Buổi 18: Chương 7_Điện môi, tụ điện", time: "video" },
        {
          content:
            "Buổi 19: Chương 8_Gauss, ampere, momen từ, lực từ, lực Lorent",
          time: "video",
        },
        { content: "Buổi 20: Chương 8_Tính từ trường", time: "video" },
        { content: "Buổi 21: Ôn tập chương 4, 5", time: "video" },
        { content: "Buổi 22: Ôn tập chương 6, 7", time: "video" },
        { content: "Buổi 23: Ôn tập chương 8", time: "video" },
      ],
      price: "Đứng đầu Bách Khoa",
      oldPrice: "550k",
      assignment: {
        img: btl_vl1,
        title:
          "Tặng bộ tài liệu BTL môn vật lý 1 anh đã mua(không áp dụng cho lớp cuối kì)",
      },
    },
    {
      id: "vl2",
      name: "Lớp Vật Lý 2",
      intro:
        "https://www.youtube.com/watch?v=X5bQszvhz5U&list=PL8RXTGoT1e8OtUFtC-_33z7QUp9fPpPDg&index=3",
      img: physic2,
      tutor: NgocLong,
      benefits: [
        "Dạy qua video .",
        "Kiến thức không đổi qua các kì",
        "Tài liệu để các bạn học vượt.",
      ],
      scheduleMiddle: [
        { content: "Buổi 1: Lim và phương pháp tính lim", time: "15/2/2024" },
        { content: "Buổi 2: Vô cùng bé", time: "15/2/2024" },
        { content: "Buổi 3: Vô cùng lớn", time: "15/2/2024" },
        { content: "Buổi 4: Tập xác định, miền giá trị", time: "15/2/2024" },
        { content: "Buổi 5: Đại số tuyến tính", time: "15/2/2024" },
        { content: "Buổi 6: Vô cùng bé", time: "15/2/2024" },
        { content: "Buổi 7: Vô cùng bé", time: "15/2/2024" },
        { content: "Buổi 8: Vô cùng bé", time: "15/2/2024" },
        { content: "Buổi 9: Vô cùng bé", time: "15/2/2024" },
      ],
      scheduleFinal: [
        { content: "Buổi 1: Lim và phương pháp tính lim", time: "15/2/2024" },
        { content: "Buổi 2: Vô cùng bé", time: "15/2/2024" },
        { content: "Buổi 3: Vô cùng lớn", time: "15/2/2024" },
        { content: "Buổi 4: Tập xác định, miền giá trị", time: "15/2/2024" },
        { content: "Buổi 5: Đại số tuyến tính", time: "15/2/2024" },
        { content: "Buổi 6: Vô cùng bé", time: "15/2/2024" },
        { content: "Buổi 7: Vô cùng bé", time: "15/2/2024" },
        { content: "Buổi 8: Vô cùng bé", time: "15/2/2024" },
        { content: "Buổi 9: Vô cùng bé", time: "15/2/2024" },
      ],
      price: "250k",
      oldPrice: "550k",
      assignment: {
        img: btl_vl2,
        title: "Tặng bộ tài liệu BTL môn vật lý 2 anh đã mua",
      },
    },
    {
      id: "hdc",
      name: "Lớp Hóa Đại Cương K24",
      intro:
        "https://www.youtube.com/watch?v=X5bQszvhz5U&list=PL8RXTGoT1e8OtUFtC-_33z7QUp9fPpPDg&index=3",
      img: chemistry,
      tutor: QuocVuong,
      method:
        "Học Livestream kết hợp video 2 buổi/tuần trong group fb kín, học toàn bộ từ lý thuyết làm bài tập luyện đề thi thử (có thể thay thế việc học trên trên). Có video, tài liệu K23 để học trước nếu muốn, record cho mỗi buổi học. Đăng kí sớm tặng khóa lập trình matlab và bài tập lớn các môn đại cương.",
      benefits: [
        "Giữa kì học livestream",
        "Cuối kì học livestream + video kì 1",
        "Có video, tài liệu để các bạn học vượt.",
      ],
      scheduleMiddle: [
        {
          content: "Buổi 1: Cấu tạo nguyên tử Phần 1 ",
          time: "29/8/2024",
        },
        {
          content: "Buổi 2: Cấu tạo nguyên tử Phần 2 ",
          time: "5/9/2024",
        },
        {
          content: "Buổi 3: Luyện tập ",
          time: "8/9/2024",
        },
        {
          content: "Buổi 4: Bảng tuần hoàn Phần 1",
          time: "12/9/2024",
        },
        {
          content: "Buổi 5: Bảng tuần hoàn Phần 2 ",
          time: "16/9/2024",
        },
        {
          content: "Buổi 6: Bảng tuần hoàn Phần 3",
          time: "22/9/2024",
        },
        {
          content: "Buổi 7: Luyện tập ",
          time: "30/9/2024",
        },
        {
          content: "Buổi 8: Liên kết hóa học Phần 1 ",
          time: "1/10/2024",
        },
        {
          content: "Buổi 9: Liên kết hóa học Phần 2 ",
          time: "3/10/2024",
        },
        {
          content: "Buổi 10: Liên kết hóa học Phần 3 ",
          time: "6/10/2024",
        },
        {
          content: "Buổi 11: Liên kết hóa học Phần 4 ",
          time: "9/10/2024",
        },
        {
          content: "Buổi 12: Luyện tập ",
          time: "11/10/2024",
        },
        {
          content: "Buổi 13: Giải đề ",
          time: "15/10/2024",
        },
        {
          content: "Buổi 14: Giải đề ",
          time: "20/10/2024",
        },
      ],
      scheduleFinal: [
        {
          content: "Buổi 15: Hiệu ứng nhiệt Phần 1 (livestream,)",
          time: "Chưa có",
        },
        {
          content: "Buổi 16: Hiệu ứng nhiệt Phần 2 (livestream)",
          time: "Chưa có",
        },
        {
          content: "Buổi 17: Nhiệt hóa học Phần 1 (livestream,)",
          time: "Chưa có",
        },
        {
          content: "Buổi 18: Nhiệt hóa học Phần 2 (livestream)",
          time: "Chưa có",
        },
        {
          content: "Buổi 19: Cân bằng hóa học Phần 1 (livestream,)",
          time: "Chưa có",
        },
        {
          content: "Buổi 20: Cân bằng hóa học Phần 2 (livestream)",
          time: "Chưa có",
        },
        {
          content: "Buổi 21: Động hóa học Phần 1 (livestream,)",
          time: "Chưa có",
        },
        {
          content: "Buổi 22: Động hóa học Phần 2 (livestream)",
          time: "Chưa có",
        },
        {
          content: "Buổi 23: Dung dịch lỏng Phần 1 (livestream,)",
          time: "Chưa có",
        },
        {
          content: "Buổi 24: Dung dịch lỏng Phần 2 (livestream)",
          time: "Chưa có",
        },
        {
          content: "Buổi 25: Dung dịch điện li Phần 1 (livestream,)",
          time: "Chưa có",
        },
        {
          content: "Buổi 26: Dung dịch điện li Phần 2 (livestream)",
          time: "Chưa có",
        },
        { content: "Buổi 27: Giải đề (livestream", time: "Chưa có" },
      ],
      price: "Đứng đầu Bách Khoa",
      oldPrice: "550k",
      assignment: {
        img: all,
        title:
          "Tặng bộ tài liệu BTL anh mua gồm các môn đại cương như giải tích, đại số tuyến tính, hóa, báo cáo TNVL,...",
      },
    },
    {
      id: "dstt",
      name: "Lớp Đại Số Tuyến Tính K24",
      intro:
        "https://www.youtube.com/watch?v=GR077ZJiZPI&list=PL8RXTGoT1e8NUhRYz1RVQ1hhHxUil7Otn&index=2",
      img: dstt,
      tutor: QuocVuong,
      method:
        "Học Livestream kết hợp video 2 buổi/tuần trong group fb kín, học toàn bộ từ lý thuyết làm bài tập luyện đề thi thử (có thể thay thế việc học trên trên). Có video, tài liệu K23 để học trước nếu muốn, record cho mỗi buổi học. Đăng kí sớm tặng khóa lập trình matlab và bài tập lớn các môn đại cương.",
      benefits: [
        "Dạy livestream group facebook kín.",
        "Lịch học anh chưa chốt",
        "Có video, tài liệu để các bạn học vượt.",
      ],
      scheduleMiddle: [
        {
          content:
            "Buổi 1: Khái niệm cơ bản, biến đổi sơ cấp ,các phép toán, hạng ma trận, ma trận nghịch đảo",
          time: "Kênh youtube cncp",
        },

        { content: "Buổi 2: Ôn tập buổi 1 + giải đề", time: "27/8/2024" },
        {
          content: "Buổi 3: Định thức và các tính chất định thức",
          time: "30/8/2024",
        },
        {
          content:
            "Buổi 4: Hệ phương trình tổng quát, hệ Cramer, hệ thuần nhất",
          time: "7/9/2024",
        },
        { content: "Buổi 5: Ôn tập + giải đề", time: "02/10/2024" },
        {
          content: "Buổi 6: Độc lập tuyến tính, phụ thuộc tuyến tính",
          time: "15/9/2024",
        },
        {
          content:
            "Buổi 7: Hạng học vecto, cơ sở, số chiều, tọa độ vecto, ma trận chuyển cơ sở",
          time: "19/10/2024",
        },
        {
          content: "Buổi 8: Không gian con + Ôn tập + giải đề",
          time: "26/10/2024",
        },
        {
          content:
            "Buổi 9: Dạng toán ứng dụng Markov, Leslie, Input-Out và thành lập hệ phương trình",
          time: "13/10/2024",
        },
        { content: "Buổi 10: Thi thử + Giải đề", time: "20/10/2024" },
        { content: "Buổi 11: Live hỏi đáp trước khi thi", time: "23/10/2024" },
      ],
      scheduleFinal: [{ content: "Đang cập nhật,...", time: "27/10/2024" }],
      price: "Đứng đầu Bách Khoa",
      oldPrice: "450k",
      assignment: {
        img: all,
        title:
          "Tặng bộ tài liệu BTL anh mua gồm các môn đại cương như đại số tuyến tính, giải tích,thí nghiệm hóa, báo cáo TNVL,...",
      },
    },
    {
      id: "xstk",
      method:
        "Học toàn bộ lý thuyết, bài tập, giải đề các năm gần đây, tổ chức livestream giải bài ôn tập, ôn thi. Nói chung đầy đủ hết và cóthể thay thế được việc học trên trường",
      name: "Lớp Xác Suất Thống Kê HK241-HK242",
      intro: "https://www.youtube.com/watch?v=Ha9RhOGlJqU",
      img: xstk,
      tutor: QuocVuong,
      benefits: [
        "Dạy livestream group facebook kín.",
        "Thứ 2 lúc (13h30), thứ 4 (19h30), trong 1 kì",
        "Có video, tài liệu để các bạn học vượt.",
      ],
      scheduleMiddle: [
        {
          content: "Buổi 1: Ôn tập kiến thức cấp 3 (video)",
          time: "30/8/2024",
        },
        {
          content: "Buổi 2: Một số khái niệm quan trọng và giải đề (video)",
          time: "4/9/2024",
        },
        {
          content:
            "Buổi 3: Xác suất có điều kiện và xác suất dạng hình học (video)",
          time: "6/9/2024",
        },
        {
          content:
            "Buổi 4: Định lý Bernoully, công thức xác suất đầy đủ (video)",
          time: "11/9/2024",
        },
        {
          content: "Buổi 5: Một số dạng toán khác và ôn tập (livestream))",
          time: "13/9/2024",
        },
        {
          content:
            "Buổi 6: Đại lượng ngẫu nhiên, hàm mật độ, hàm phân phối (livestream)",
          time: "18/9/2024",
        },
        {
          content:
            "Buổi 7: Kỳ vọng, phương sai, độ lệch chuẩn, mốt, trung vị (livestream) ",
          time: "20/9/2024",
        },

        {
          content: "Buổi 10: Phân phối mũ và poisson(livestream)",
          time: "25/9/2024 ",
        },
        {
          content: "Buổi 11: Phân phối chuẩn, nhị thức, đều (livestream)",
          time: "27/9/2024 ",
        },
        {
          content: "Buổi 12: Phân phối siêu bội và định lý giới hạn trung tâm",
          time: "2/10/2024 ",
        },
        {
          content: "Buổi 13: Vecto ngẫu nhiên (livestream)",
          time: "4/10/2024 ",
        },
        {
          content: "Buổi 14: Thi thử và sửa đề (livestream)",
          time: "9/10/2024",
        },
        {
          content: "Buổi 15: Hỏi đáp và ôn tập trước khi thi (livestream)",
          time: "20/10/2024",
        },
      ],
      scheduleFinal: [
        { content: "Buổi 16: Ước lượng điểm, ước lượng không chệch", time: "" },
        { content: "Buổi 17: Kiểm định 1 mẫu", time: "" },
        { content: "Buổi 18: Kiểm định tỷ lệ 1 mẫu", time: "" },
        { content: "Buổi 19: Kiểm định trung bình 1 mẫu", time: "" },
        { content: "Buổi 20: Kiểm định phương sai 1 mẫu", time: "" },
        { content: "Buổi 21: Kiểm định tỷ lệ 2 mẫu", time: "" },
        { content: "Buổi 22: Kiểm định trung bình 2 mẫu", time: "" },
        {
          content: "Buổi 23: Kiểm định trung bình 2 mẫu không độc lập",
          time: "",
        },
        { content: "Buổi 24: Phân tích phương sai một nhân tố", time: "" },
        {
          content:
            "Buổi 25: Kiểm định về tính độc lập, giả thuyết về quy luật phân phối",
          time: "",
        },
        { content: "Buổi 26: Phân tích hồi quy", time: "" },
        { content: "Buổi 27: Mô hình hồi quy tuyến tính đơn", time: "" },
        { content: "Buổi 28: Các dạng toán liên quan", time: "" },
      ],
      price: "450K/cả kì",
      oldPrice: "600k",
      assignment: {
        img: btl_xstk,
        title:
          "Tặng bộ tài liệu BTL XSTK và các môn khác anh đã mua của tất cả các khoa.",
      },
    },
    {
      id: "ppt",
      name: "Lớp Phương Pháp Tính HK241-242",
      img: PPT,
      method:
        "Học toàn bộ lý thuyết, bài tập, giải đề các năm gần đây, tổ chức livestream giải bài ôn tập, ôn thi. Nói chung đầy đủ hết và cóthể thay thế được việc học trên trường",
      intro:
        "https://www.youtube.com/watch?v=O7WrQoLtoEw&list=PL8RXTGoT1e8NgMpj5DiqBpw6rW-1AFEwB",
      tutor: QuocVuong,
      benefits: [
        "Dạy video group facebook kín.",
        "Học 2 buổi trên tuần trong 1 tháng",
        "Có video , tài liệu để các bạn học vượt.",
      ],
      scheduleMiddle: [
        { content: "Buổi 1: Sai số và số gần đúng", time: "8/9/2024" },
        { content: "Buổi 2: Sai số và số gần đúng", time: "15/9/2024" },
        { content: "Buổi 3: Hàm phi tuyến", time: "19/9/2024" },
        {
          content: "Buổi 4: Hàm phi tuyến",
          time: "22/9/2024",
        },
        {
          content: "Buổi 5: Hàm phi tuyến",
          time: "26/9/2024",
        },
        { content: "Buổi 6:  Hệ phương trình", time: "29/9/2024" },
        { content: "Buổi 7:  Hệ phương trình", time: "03/10/2024" },
        { content: "Buổi 8:  Hệ phương trình", time: "06/10/2024" },
        { content: "Buổi 9:  Hệ phương trình", time: "9/10/2024" },
        { content: "Buổi 10:  Ôn tập giải đề", time: "13/10/2024" },
        {
          content: "Buổi 11:  Ôn tập giải đề",
          time: "16/10/2024",
        },
      ],
      scheduleFinal: [{ content: "Chưa mở", time: "Chưa có" }],
      price: "350K/Cả kì",
      oldPrice: "500k",
      assignment: {
        img: all,
        title:
          "Tặng bộ tài liệu BTL anh mua gồm các môn đại cương như giải tích 1, đại số tuyến tính, thí nghiệm hóa đc, báo cáo TNVL,...(không áp dụng cho lớp cuối kì)",
      },
    },
  ];
  const dataSource = [
    {
      mssv: "2310207",
      name: "Dương Gia Bảo",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313046",
      name: "Đoàn Khánh Tân",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311845",
      name: "Hoàng Diệu Linh",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2352145",
      name: "Hoàng Kim Cương",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313230",
      name: "Huỳnh Hoàng Thiện",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313230",
      name: "Huỳnh Hoàng Thiện",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313114",
      name: "Huỳnh Trung Thành",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311974",
      name: "Huỳnh văn Lợi",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313450",
      name: "Lê Duy pu Tin",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2312161",
      name: "Lê Đoàn Hồng Nam",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2353230",
      name: "Lê Ngọc Trí",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2352522",
      name: "Lê Nguyễn Nam Khánh",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2312017",
      name: "Lê Thị Xuân Mai",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2352429",
      name: "Lư Thuận Hưng",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311430",
      name: "Lư Tuấn Khang",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2352038",
      name: "Nguyễn Bùi Tuấn Anh",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2310365",
      name: "Nguyễn Chu Nguyên Chương",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2352477",
      name: "Nguyễn Dương Khang",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2312265",
      name: "Nguyễn Đắc Nghĩa",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2351037",
      name: "Nguyễn Đình Hoàng Kha",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2352850",
      name: "Nguyễn Hiếu Nhân",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311055",
      name: "Nguyễn Huy Hoàng",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311055",
      name: "Nguyễn Huy Hoàng",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311389",
      name: "Nguyễn Kha",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311188",
      name: "Nguyễn Lâm Huy",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311521",
      name: "Nguyễn Ngọc Kim Khánh",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2353188",
      name: "Nguyễn Trung Tín",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2310536",
      name: "Phạm Anh Dũ",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2353080",
      name: "Phạm Duy Tân",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2353080",
      name: "Phạm Duy Tân",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "1914875",
      name: "Phan Lê Tú Quyên",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313247",
      name: "Phan Quốc Thiện",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2312450",
      name: "Trần Chí Nhân",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2312450",
      name: "Trần Chí Nhân",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313224",
      name: "Trần Đăng Hoàng Thiên",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2312760",
      name: "Trần Minh Phương",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2312855",
      name: "Trần Minh Quân",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313316",
      name: "Trương Đức Thịnh",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2310985",
      name: "Trương Nguyễn Minh Hiếu",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313073",
      name: "Võ Tấn",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313200",
      name: "Vũ Đức Thắng",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2310041",
      name: "Trần Phương Trường An",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311648",
      name: "Trịnh Hoàng Anh Khoa",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2313216",
      name: "Nguyễn Bùi Khánh Thiên",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2311847",
      name: "Huỳnh Nhật Linh",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "2352550",
      name: "Cao Lê Minh Khoa",
      point: 10,
      school: "Trường Đại Học Bách Khoa HCM",
    },
    {
      mssv: "......",
      name: ".........",
      point: 10,
      school: ".........",
    },
    {
      mssv: "......",
      name: ".........",
      point: 10,
      school: ".........",
    },
    {
      mssv: "......",
      name: ".........",
      point: 10,
      school: ".........",
    },

    {
      mssv: "Còn rất nhiều",
      name: "Lười code thêm",
      point: 10,
      school: ".........",
    },
  ];
  const columns = [
    {
      title: "Mã số sinh viên",
      dataIndex: "mssv",
      key: "mssv",
    },
    {
      title: "Tên sinh viên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Điểm thi",
      dataIndex: "point",
      key: "point",
    },
    {
      title: "Trường",
      dataIndex: "school",
      key: "school",
    },
  ];
  const [statusCourseBasic, setStatusCourseBasic] = useState(false);
  const [statusCourseAdvance, setStatusCourseAdvance] = useState(false);
  const [statusImg, setStatusImg] = useState(false);
  const [course, setCourse] = useState(false);
  const [isXSTK, setIsXSTK] = useState(false);
  const [isPPT, setIsPPT] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const result = DataCourse.find((course) => {
      return course.id === id;
    });
    setIsXSTK("xstk" === id);
    setIsPPT("ppt" === id);
    document.title = "Thông tin " + result.name.toLowerCase();
    setCourse(result);
  });
  return (
    <div className={cx("container")}>
      <div>
        <div className={cx("intro")}>
          <h1>{course?.name}</h1>
          {course?.tutor?.name === "Nguyễn Quốc Vương" ? (
            <span>
              Người dạy là mình nhé,{" "}
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100086390823801"
              >
                Nguyễn Quốc Vương
              </a>
              , sinh viên khóa K19 tốt nghiệp loại giỏi tháng 6/2024, hiện tại
              đang học Thạc Sĩ ở trường Bách Khoa HCM, mình đã dạy từ năm 2019
              nên các bạn yên tâm về chất lượng nhé.
            </span>
          ) : (
            <span>
              Người dạy là anh{" "}
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100007913497459"
              >
                Ngọc Long
              </a>
              , sinh viên khóa K18 tốt nghiệp năm 2024, hiện tại đang làm
              Research and Development Enginee tại công ty Juki , anh đã dạy từ
              năm 2018 nên các bạn yên tâm về chất lượng nhé.
            </span>
          )}
          <div className={cx("m_member")}>
            <h2>Đối tượng tham gia</h2>
            <span>
              Sinh viên đại trà, chất lượng cao, Việt Pháp của Đại Học Bách Khoa
              HCM. Ngoài ra, sinh viên Bách Khoa Đà Nẵng, KHTN và 1 số trường
              giống chương trình.
            </span>
          </div>
          <div>
            <div className={cx("advertisement-mobile")}>
              <div className={cx("container-advertisement")}>
                <div className={cx("container-image")}>
                  <img className={cx("image")} src={course?.img}></img>

                  <h5 style={{ margin: 0 }}>{course?.price}</h5>
                  <Button
                    link={course?.tutor?.linkin}
                    text={"Liên hệ đăng ký"}
                  ></Button>
                </div>

                <div className={cx("detail-advertisement")}>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faUser}
                    ></FontAwesomeIcon>
                    <span>Sinh viên Bách Khoa</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faFacebook}
                    ></FontAwesomeIcon>
                    <span>Học Livestream</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faWordpress}
                    ></FontAwesomeIcon>
                    <span>Hai buổi/tuần học hơn 5 tháng</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faTimeline}
                    ></FontAwesomeIcon>
                    <span>Bận có thể xem lại video </span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faCameraRetro}
                    ></FontAwesomeIcon>
                    <span>Hỗ trợ bài tập tớp các môn</span>
                  </div>
                </div>
              </div>
            </div>

            {(isXSTK || isPPT) && (
              <>
                <div className={cx("member")}>
                  <div className={cx("hot")}>
                    <h2>Ưu đãi 200 học viên đăng kí sớm</h2>
                    <img src={iconHot}></img>
                  </div>
                  <span className={cx("warn")}>
                    Lưu ý, chỉ áp dụng từ ngày 10/8-26/8/2024
                  </span>
                  <h3 className={cx("gift")}>Phần quà giá kiến thức </h3>
                  <ul>
                    <li>
                      Tặng khóa học chuyên ngành nhiều khoa để thực tập và đi
                      làm (danh sách phía dưới)
                    </li>
                    <li>
                      Tặng BTL các kì trước và BTL các môn chính trị (vẫn còn
                      mua thêm){" "}
                    </li>
                    <li>
                      Được sử dụng 2 file cuốn sách XSTK (giữa kì, cuối kì) anh
                      tự soạn gồm đầy đủ lý thuyết, giải tất cả các câu trọng
                      tâm nhưng năm gần đây (~400 trang). Lớp PPT thì có 1 quyển
                      thôi nha
                    </li>
                  </ul>
                  <h3 className={cx("gift")}>
                    Phần quà giá trị vật chất tặng Voucher giảm 50k
                  </h3>
                </div>
                <span>Điều kiện: </span>
                <ul>
                  <li>Áp dụng từ ngày 10/8-26/8/2024</li>
                  {isXSTK ||
                    (isXSTK && (
                      <li>Thành toán tiền học phí khi đăng kí 500k-50k=450k</li>
                    ))}
                  {isPPT && (
                    <li>Thành toán tiền học phí khi đăng kí 400k-50k=350k</li>
                  )}
                </ul>
                {isXSTK && (
                  <div className={cx("member")}>
                    <h2>Đăng kí lớp học</h2>
                    <ul>
                      <li>
                        Đăng kí lớp có voucher 450k tại đây:{" "}
                        <a
                          target="blank"
                          href="https://docs.google.com/forms/d/1RSLLtMgnyFYuPMKF-Gsg-MruvD2BRcd1-NP_mAYRiJ4/viewform?edit_requested=true"
                        >
                          Đăng kí có voucher
                        </a>{" "}
                      </li>
                      <li>
                        Đăng kí lớp trả sau 500k tại đây:{" "}
                        <a
                          target="blank"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSeLGBTPtxZ_Ih3i91yIijL2qhlikeT8Tu88_bY7AECmzZmnsg/viewform?usp=sf_link"
                        >
                          Đăng kí không voucher
                        </a>{" "}
                      </li>
                    </ul>
                    <span>
                      Ngoài ra, có thể ib{" "}
                      <a
                        target="blank"
                        href="https://www.facebook.com/profile.php?id=100086390823801"
                      >
                        a Vương
                      </a>{" "}
                      đăng kí trực tiếp. Lưu ý, khi đăng kí sẽ không được hoàn
                      học phí hoặc có trách nhiệm phải trả học phí vì tài liệu
                      sẽ được gửi ngay khi đăng kí.
                    </span>
                  </div>
                )}
                {isPPT && (
                  <div className={cx("member")}>
                    <h2>Đăng kí lớp học</h2>
                    <ul>
                      <li>
                        Đăng kí lớp có voucher 350k tại đây:{" "}
                        <a
                          target="blank"
                          href="https://docs.google.com/forms/d/1RSLLtMgnyFYuPMKF-Gsg-MruvD2BRcd1-NP_mAYRiJ4/viewform?edit_requested=true"
                        >
                          Đăng kí có voucher
                        </a>{" "}
                      </li>
                      <li>
                        Đăng kí lớp trả sau 400k tại đây:{" "}
                        <a
                          target="blank"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSeLGBTPtxZ_Ih3i91yIijL2qhlikeT8Tu88_bY7AECmzZmnsg/viewform?usp=sf_link"
                        >
                          Đăng kí không voucher
                        </a>{" "}
                      </li>
                    </ul>
                    <span>
                      Ngoài ra, có thể ib{" "}
                      <a href="https://www.facebook.com/profile.php?id=100086390823801">
                        a Vương
                      </a>{" "}
                      đăng kí trực tiếp. Lưu ý, khi đăng kí sẽ không được hoàn
                      học phí hoặc có trách nhiệm phải trả học phí vì tài liệu
                      sẽ được gửi ngay khi đăng kí.
                    </span>
                  </div>
                )}
              </>
            )}

            <div className={cx("member")}>
              <h2>Số lượng học viên</h2>
              <div>
                {isXSTK || (
                  <>
                    <div className={cx("member-detail")}>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K20: 315 học viên</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K21: 455 học viên</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K22: 720 học viên</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K23: 822+ học viên</span>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className={cx("member")}>
              <div>
                {isXSTK && (
                  <>
                    <div>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K21: 520+ học viên</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K22: 680+ học viên</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={cx("member")}>
              <h2>Hình thức học</h2>
              <span>{course.method}</span>
            </div>
            {/* <div className={cx("member")}>
              <div>
                {isXSTK || (
                  <>
                    <div className={cx("member-detail")}>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K19: 315+ học viên</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K20: 355++ học viên</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K21: 520+ học viên</span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        color="#494949"
                        fontSize={"1.2rem"}
                        icon={faUsers}
                      ></FontAwesomeIcon>
                      <span>Khóa K22: 680+ học viên</span>
                    </div>
                  </>
                )}
              </div>
            </div> */}
            {isXSTK || isPPT || (
              <div className={cx("member")}>
                <h2>Voucher khi mua khóa Combo năm nhất</h2>
                <div className={cx("combo")}>
                  <div>
                    <img className={cx("hot")} src={hot}></img>
                    <span>
                      Giải tích 1 + Đại số tuyến tính: <span>tặng 100k</span>
                    </span>
                  </div>
                  <div>
                    <img className={cx("hot")} src={hot}></img>
                    <span>
                      Giải tích 1 + Hóa đại cương: <span>tặng 100k</span>
                    </span>
                  </div>
                </div>
                <div>
                  <i>
                    Chỉ áp dụng giá combo khi đăng kí sớm 23/8-30/8/2024 và 200
                    bạn đầu tiên
                  </i>
                </div>
              </div>
            )}
            {isXSTK || isPPT || (
              <div className={cx("member1", { member1: true })}>
                <h2>Thành tích khóa K23</h2>
                <span className={cx("point")}>Sơ lượt</span>
                <ul>
                  <li>
                    Khóa K23 toàn trường khoảng 5000 bạn nhưng học viên CNCP
                    khoảng 3247 suất đăng kí
                  </li>
                  <li>Khóa K23 có hàng trăm điểm 10 và rất nhiều điểm cao</li>
                  <li>
                    {" "}
                    Học viên suất xuất nhất: Trần Chí Nhân 2312450 Khoa điện với
                    thành tích đạt 10 điểm tất cả các môn giải tích 1, giải tích
                    2, đại số tuyến tính, hóa đại cương, phương pháp tính,...{" "}
                  </li>
                </ul>
                <span className={cx("point")}>
                  Danh sách điểm 10 học viên K23
                </span>
                <br></br>
                <a href="https://www.facebook.com/groups/hcmutcncp/permalink/1412075199747027/?rdid=w4OoJeWyyRF8SUpH&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fp%2F5rY1xffDBzHgVDHH%2F">
                  Bài viết tuyên dương
                </a>
                <div className={cx("table")}>
                  <Table
                    pagination={{ pageSize: 5 }}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </div>
            )}
            <div className={cx("document")}>
              <div>
                <h2>Nội dung học</h2>
              </div>
              <div>
                <div className={cx("program")}>
                  <span>
                    <i>
                      Lưu ý, thứ tự và nội dung học có thể thay đổi để phù hợp
                      với đề thi HK241
                    </i>
                  </span>
                </div>
                <div
                  onClick={() => {
                    setStatusCourseBasic((state) => {
                      return !state;
                    });
                  }}
                  className={cx("basic-course")}
                >
                  <div>
                    <FontAwesomeIcon
                      className={cx("icon")}
                      icon={faMinus}
                    ></FontAwesomeIcon>
                    <span>1. Lịch dạy giữa kỳ</span>
                  </div>
                  <span>Lịch dự kiến</span>
                </div>
                {statusCourseBasic && (
                  <div>
                    {course?.scheduleMiddle?.map((timeline) => {
                      return (
                        <Subject
                          name={timeline.content}
                          weight={timeline.time}
                        ></Subject>
                      );
                    })}
                  </div>
                )}

                <div
                  className={cx("basic-course")}
                  onClick={() => {
                    setStatusCourseAdvance((state) => {
                      return !state;
                    });
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      className={cx("icon")}
                      color="#f05123"
                      icon={faMinus}
                    ></FontAwesomeIcon>
                    <span>2. Lịch dạy cuối kỳ</span>
                  </div>
                  <span>Lịch dự kiến</span>
                </div>
                <div>
                  {statusCourseAdvance && (
                    <div>
                      {course?.scheduleFinal?.map((timeline) => {
                        return (
                          <Subject
                            name={timeline?.content}
                            weight={timeline?.time}
                          ></Subject>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={cx("assignment-container")}>
              <h2>Ưu đãi đăng kí sớm</h2>
              <div className={cx("assignment")}>
                {isXSTK || isPPT || (
                  <>
                    {" "}
                    <span>
                      Tặng một khóa học lập trình matlab cho tân sinh viên K24.
                      (kiến thức bắt buột cho tân sv)
                    </span>
                    <div>
                      <img src={matlab}></img>
                    </div>
                  </>
                )}
                {isPPT && (
                  <>
                    {" "}
                    <span>
                      Tặng combo 2 file sách chinh phục siêu chất lượng gồm lý
                      thuyết và giải toàn bộ đề thi gần đây
                    </span>
                    <div>
                      <img src={sachppt}></img>
                    </div>
                  </>
                )}
                {isXSTK && (
                  <>
                    {" "}
                    <span>
                      Tặng combo 2 file sách chinh phục siêu chất lượng gồm lý
                      thuyết và giải toàn bộ đề thi gần đây
                    </span>
                    <div>
                      <img src={sach}></img>
                    </div>
                  </>
                )}
                <span>{course?.assignment?.title}</span>
                <div>
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setStatusImg(true);
                    }}
                    src={course?.assignment?.img}
                  ></img>
                </div>

                {statusImg && (
                  <div
                    onClick={() => {
                      setStatusImg(false);
                    }}
                    className={cx("assignment-img", { active: true })}
                  >
                    <img src={course?.assignment?.img}></img>
                  </div>
                )}
              </div>
            </div>

            {isXSTK && (
              <div className={cx("assignment-container")}>
                <h2>Ưu đãi 200 học viên đăng kí sớm</h2>
                <div className={cx("assignment")}>
                  <span>
                    Chỉ dành cho các bạn đã từng học anh Vương ít nhất một môn
                    trước đó hoặc 200 slot dành cho các bạn chưa từng đăng kí
                    anh đầu tiên (đăng kí gói sớm cả kì).
                  </span>
                  <div>
                    <span>
                      Bạn sẽ được chọn một trong các khóa học sau để học chung
                      với anh
                    </span>
                    <ul className={cx("uudai")}>
                      <li>Khóa anh văn Toeic nghe đọc 0-700+</li>
                      <li>Khóa anh văn Toeic nói viết 0-300+</li>
                      <li>
                        Khóa học từ cơ bản đên nâng cao Autocad (xây dựng, cơ
                        khí,môi trường, giao thông,điện,...) (hết slot)
                      </li>
                      <li>
                        Khóa học từ cơ bản đên nâng cao Revit (xây dựng, cơ
                        khí,môi trường, giao thông,điện,...)(hết slot)
                      </li>
                      <li>
                        Học vẽ SolidWorks (xây dựng, điện, môi trường ,oto, cơ
                        khí, cơ điện tử, hàng không, tàu thủy,...cần)
                      </li>
                      <li>
                        Khóa học PLC cho sinh viên tự động hóa, cơ điện tử, cơ
                        khí, oto, bảo dưỡng,..
                      </li>
                      <li>
                        Lập trình vi điều khiển STM32 (điện, cơ điện tử, máy
                        tính, vật lý kỹ thuật...cần){" "}
                      </li>
                      <li>
                        Lập trình vi điều khiển ESP32 (điện, cơ điện tử,máy
                        tính, vật lý kỹ thuật...cần)
                      </li>
                      <li>
                        Lập trình fullstack web Python (điện, cơ điện tử, máy
                        tính, vật lý kỹ thuật...cần){" "}
                      </li>
                      <li>Khóa học về Docker(máy tính , điện,..)</li>
                      <li>
                        Khóa học AI cơ bản Machine Learning(máy tính ,cơ điện tử
                        ,điện,..)
                      </li>
                      <li>
                        Khóa học IOT cơ bản Machine Learning(máy tính ,cơ điện
                        tử ,điện, cơ khí..)
                      </li>
                      <li>Lập trình C (điện, cơ điện tử, cơ khí 96...)</li>

                      <li>Lập trình html, css, javascript (đã hết slot)</li>
                      <li>
                        Lập trình backend Nodejs (điện, máy tính cơ khí 96,...)
                      </li>
                      <li>
                        Lập trình backend C# (aspnet core) (điện, máy tính cơ
                        khí 96,...)
                      </li>
                    </ul>
                    <span>
                      Tài liệu anh mua để học nên khá đắt tiền nên chỉ cho một
                      số lượng slot nhất định để tránh ảnh hưởng đến chủ sở hữu,
                      nhanh tay nhé.{" "}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {isPPT && (
              <div className={cx("assignment-container")}>
                <h2>Ưu đãi học viên cũ</h2>
                <div className={cx("assignment")}>
                  <span>
                    Chỉ dành cho các bạn đã từng học anh Vương ít nhất một môn
                    trước đó hoặc 100 slot dành cho các bạn chưa từng đăng kí
                    anh đầu tiên (đăng kí gói sớm cả kì).
                  </span>
                  <div>
                    <span>
                      Bạn sẽ được chọn một trong các khóa học sau để học chung
                      với anh
                    </span>
                    <ul className={cx("uudai")}>
                      <li>Khóa anh văn Toeic nghe đọc 0-700+</li>
                      <li>Khóa anh văn Toeic nói viết 0-300+</li>
                      <li>
                        Khóa học từ cơ bản đên nâng cao Autocad (xây dựng, cơ
                        khí,môi trường, giao thông,điện,...)
                      </li>
                      <li>
                        Khóa học từ cơ bản đên nâng cao Revit (xây dựng, cơ
                        khí,môi trường, giao thông,điện,...)
                      </li>
                      <li>
                        Học vẽ SolidWorks (xây dựng, điện, môi trường ,oto, cơ
                        khí, cơ điện tử, hàng không, tàu thủy,...cần)
                      </li>
                      <li>
                        Khóa học PLC cho sinh viên tự động hóa, cơ điện tử, cơ
                        khí, oto, bảo dưỡng,..
                      </li>
                      <li>
                        Lập trình vi điều khiển STM32 (điện, cơ điện tử, máy
                        tính, vật lý kỹ thuật...cần){" "}
                      </li>
                      <li>
                        Lập trình vi điều khiển ESP32 (điện, cơ điện tử,máy
                        tính, vật lý kỹ thuật...cần)
                      </li>
                      <li>
                        Lập trình fullstack web Python (điện, cơ điện tử, máy
                        tính, vật lý kỹ thuật...cần){" "}
                      </li>
                      <li>Khóa học về Docker(máy tính , điện,..)</li>
                      <li>
                        Khóa học AI cơ bản Machine Learning(máy tính ,cơ điện tử
                        ,điện,..)
                      </li>
                      <li>
                        Khóa học IOT cơ bản Machine Learning(máy tính ,cơ điện
                        tử ,điện, cơ khí..)
                      </li>
                      <li>Lập trình C (điện, cơ điện tử, cơ khí 96...)</li>

                      <li>Lập trình html, css, javascript (đã hết slot)</li>
                      <li>
                        Lập trình backend Nodejs (điện, máy tính cơ khí 96,...)
                      </li>
                      <li>
                        Lập trình backend C# (aspnet core) (điện, máy tính cơ
                        khí 96,...)
                      </li>
                    </ul>
                    <span>
                      Tài liệu anh mua để học nên khá đắt tiền nên chỉ cho một
                      số lượng slot nhất định để tránh ảnh hưởng đến chủ sở hữu,
                      nhanh tay nhé.{" "}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={cx("content")}></div>
      </div>
      <div className={cx("test")}></div>
      <div className={cx("advertisement-desktop")}>
        <div className={cx("container-advertisement")}>
          <div className={cx("container-image")}>
            <img className={cx("image")} src={course?.img}></img>
            <a
              target="_blank"
              href={course?.intro}
              className={cx("container-play")}
            >
              <FontAwesomeIcon
                className={cx("play")}
                icon={faCirclePlay}
              ></FontAwesomeIcon>
            </a>
          </div>
          <h5>{course?.price}</h5>
          <Button
            link={course?.tutor?.linkin}
            text={"Liên hệ đăng ký"}
            blank={true}
          ></Button>
          <div className={cx("detail-advertisement")}>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faUser}
              ></FontAwesomeIcon>
              <span>Sinh viên Bách Khoa</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faFacebook}
              ></FontAwesomeIcon>
              <span>Học Livestream</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faWordpress}
              ></FontAwesomeIcon>
              <span>Hai buổi/tuần học hơn 5 tháng</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faTimeline}
              ></FontAwesomeIcon>
              <span>Bận có thể xem lại video </span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faCameraRetro}
              ></FontAwesomeIcon>
              <span>Hỗ trợ bài tập tớp các môn</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Course;
