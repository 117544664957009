import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react/headless";
import useDebounce from "../../../hooks/useDebounce";
import Wrapper from "../Popper/Wrapper";
import classNames from "classnames/bind";
import styles from "./Search.module.scss";
import ItemSearch from "./ItemSearch/ItemSearch";
import gt1 from "../../../assets/images/Course/gt1.png";
import xstk from "../../../assets/images/Course/xacsuatthongke.png";
import maytinh from "../../../assets/images/maytinh.png";
import xaydung from "../../../assets/images/xaydung.png";
import av from "../../../assets/images/newfeed/av.jpg";
import ai from "../../../assets/images/CourseFree/ai.jpg";
const cx = classNames.bind(styles);
function Search() {
  const DataSearch = [
    {
      header: "Lớp Học Livestream Đại Cương",
      Path: "/",
      data: [
        { name: "Lớp Giải Tích 1", path: "/course/gt1", image: gt1 },
        { name: "Lớp Xác Suất Thống Kê", path: "/course/xstk", image: xstk },
      ],
    },
    {
      header: "Tài Liệu Các Khoa",
      Path: "/",
      data: [
        { name: "Khoa Máy Tính", path: "learning/cse", image: maytinh },
        { name: "Khoa xây dựng", path: "learning/xd", image: xaydung },
      ],
    },
    {
      header: "Tài Liệu Tổng Hợp",
      Path: "/selection",
      data: [
        { name: "Tài Liệu Anh văn Toiec", path: "/document", image: av },
        { name: "Tài Liệu Lập Trình", path: "/document", image: ai },
      ],
    },
  ];
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [focus, setFocus] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [show, setShow] = useState(true);
  const [active, setActive] = useState(true);
  const [unactive, setUnActive] = useState(true);

  const publicValue = useDebounce(searchValue, 500);

  useEffect(() => {
    if (!searchValue.trim()) {
      return;
    }

    setSpinner(true);
    (async () => {
      //dataUsers = await search(publicValue);
      setSearchResult(DataSearch);
      setSpinner(false);
    })();
  }, [publicValue]);

  const inputRef = useRef();
  const handleOutside = () => {
    setShow(false);
  };

  return (
    <>
      <div className={cx("search", { focus: focus })}>
        <Tippy
          visible={searchValue.length > 0 && show}
          interactive={true}
          onClickOutside={handleOutside}
          render={(attrs) => (
            <div className={cx("search_result")} tabIndex="-1" {...attrs}>
              <Wrapper>
                <span className={cx("result")}>Kết quả tìm kiếm</span>
                {searchResult?.map((item) => {
                  return (
                    <ItemSearch
                      link={item.Path}
                      title={item.header}
                      data={item.data}
                    ></ItemSearch>
                  );
                })}
                <span
                  style={{ paddingTop: 10, paddingLeft: 10, paddingBottom: 10 }}
                >
                  <i>Tính năng đang mặc định, sẽ cập nhật ở giai đoạn 2</i>
                </span>
              </Wrapper>
            </div>
          )}
        >
          <input
            ref={inputRef}
            type="text"
            value={searchValue}
            onFocus={() => {
              setShow(true);
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder="Tìm kiếm lớp, tài liệu, video,..."
            spellCheck={false}
          ></input>
        </Tippy>

        {!searchValue || spinner || (
          <button
            onClick={() => {
              inputRef.current.focus();
              setSearchValue("");
            }}
          >
            <FontAwesomeIcon
              color="#7c7c7c"
              icon={faCircleXmark}
            ></FontAwesomeIcon>
          </button>
        )}
        {!searchValue && (
          <button>
            <FontAwesomeIcon
              color="#7c7c7c"
              icon={faMagnifyingGlass}
            ></FontAwesomeIcon>
          </button>
        )}
        {!spinner || (
          <button>
            <FontAwesomeIcon
              color="#7c7c7c"
              className={cx("spinner")}
              icon={faSpinner}
            ></FontAwesomeIcon>
          </button>
        )}
      </div>
      <div className={cx("bt-contain")}>
        <button className={cx("bt-mobile")}>
          <FontAwesomeIcon
            color="#7c7c7c"
            icon={faMagnifyingGlass}
          ></FontAwesomeIcon>
        </button>
      </div>
    </>
  );
}

export default Search;
