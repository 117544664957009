import classNames from "classnames/bind";
import { NavLink } from "react-router-dom";
import styles from "./MenuItem.module.scss";
const cx = classNames.bind(styles);
function MenuItem({ to, title, icon, onclick }) {
  return (
    <NavLink
      className={(nav) => cx("menu-item", { active: nav.isActive })}
      to={to}
      onClick={onclick}
      title={title}
    >
      {icon}
      <span className={cx("menu-title")}>{title}</span>
    </NavLink>
  );
}

export default MenuItem;
