import classNames from "classnames/bind";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  ConversationHeader,
  Avatar,
  VoiceCallButton,
  InfoButton,
  VideoCallButton,
} from "@chatscope/chat-ui-kit-react";
import emilyIco from "../../../assets/images/logocncp.png";
import style from "./ChatAI.module.scss";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import chat from "../../../assets/images/chat.png";
const cx = classNames.bind(style);
function ChatAI() {
  const API_KEY = "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";
  // "Explain things like you would to a 10 year old learning how to code."
  const systemMessage = {
    //  Explain things like you're talking to a software professional with 5 years of experience.
    role: "system",
    content:
      "Explain things like you're talking to a software professional with 2 years of experience.",
  };
  const [show, setShow] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: "Hi mình là chat AI, bạn muốn hỏi gì nè<!DOCTYPE html> ",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };
  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ]);
        setIsTyping(false);
      });
  }
  return (
    <>
      <div
        onClick={() => {
          setShow((state) => {
            return !state;
          });
        }}
        className={cx("bt-open")}
      >
        <img src={chat}></img>
        <span className={cx("menu-title")}>Chat AI</span>
        <div className={cx("circle-big-2")}></div>
        <div className={cx("circle-big-1")}></div>
        <div className={cx("circle-big")}></div>
      </div>
      {show && (
        <div className={cx("wrapper")}>
          <div className={cx("Container")}>
            <MainContainer>
              <ChatContainer>
                <ConversationHeader style={{ fontSize: "1.4rem" }}>
                  <Avatar
                    style={{ fontSize: "1.2rem" }}
                    src={emilyIco}
                    name="Emily"
                    status="available"
                  />
                  <ConversationHeader.Content
                    userName="Chat AI-CNCP"
                    info="Đang hoạt động"
                  />
                  <ConversationHeader.Actions
                    style={{ fontSize: "1.4rem", color: "#fff" }}
                  >
                    <VoiceCallButton />
                    <VideoCallButton />
                    <InfoButton />
                    <div
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <FontAwesomeIcon
                        className={cx("bt-close")}
                        icon={faXmark}
                      ></FontAwesomeIcon>
                    </div>
                  </ConversationHeader.Actions>
                </ConversationHeader>
                <MessageList
                  scrollBehavior="smooth"
                  style={{
                    color: "#0084ff",
                    marginTop: 10,
                  }}
                  typingIndicator={
                    isTyping ? (
                      <TypingIndicator
                        style={{ fontSize: "1.4rem", color: "#fff" }}
                        content="Chat-CNCP đang soạn"
                      />
                    ) : null
                  }
                >
                  {messages.map((message, i) => {
                    console.log(message);
                    return (
                      <Message
                        style={{
                          color: "#fff",
                          fontSize: "1.6rem",

                          textAlign: "start",
                        }}
                        key={i}
                        model={message}
                      ></Message>
                    );
                  })}
                </MessageList>
                <MessageInput
                  style={{
                    color: "#0084ff",
                    fontSize: "1.6rem",
                  }}
                  placeholder="Nhập câu hỏi..."
                  onSend={handleSend}
                />
              </ChatContainer>
            </MainContainer>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatAI;
