import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import styles from "./SubjectItem.module.scss";
const cx = classNames.bind(styles);
function SubjectItem({
  name,
  price,
  path,
  text,
  pt5,
  onclick,
  handleActive,
  fee,
}) {
  let priceText = price === 0 ? "Free" : price + ".000đ";
  priceText = text || priceText;
  priceText = pt5 ? price : priceText;
  const free = price === 0;
  price === 0 && (priceText = "free");
  priceText = fee ? fee : priceText;
  return (
    <>
      <a
        href={path}
        target="_blank"
        onClick={() => {
          onclick && onclick(true);
          handleActive && handleActive(true);
        }}
        className={cx("wrapper", { active: true })}
      >
        <div>
          <FontAwesomeIcon
            className={cx("icon")}
            icon={faCircle}
          ></FontAwesomeIcon>
          <span>{name || "Đang cập nhật thêm..🤣"}</span>
        </div>
        <span className={cx("price", { free: free })}>{priceText}</span>
      </a>
    </>
  );
}

export default SubjectItem;
