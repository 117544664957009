import classNames from "classnames/bind";
import styles from "./Loading.module.scss";
const cx = classNames.bind(styles);
function Loading() {
  return (
    <div className={cx("wrapper")}>
      <div style={{ "--delay": 1 }}></div>
      <div style={{ "--delay": 2 }}></div>
      <div style={{ "--delay": 3 }}></div>
      <div style={{ "--delay": 4 }}></div>
    </div>
  );
}

export default Loading;
