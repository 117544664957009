import Assignment from "../Components/pages/Assignment/Assignment";
import Document from "../Components/pages/Document/Document";
import Home from "../Components/pages/Home/Home";
import Login from "../Components/pages/Login/Login";
import Learning from "../Components/pages/Learning/Learning";
import Course from "../Components/pages/Course/Course";
import PT5 from "../Components/pages/PT5/PT5";
import Howtocalculatepoints from "../Components/pages/Blogs/Howtocalculatepoints/Howtocalculatepoints";

const publicRoute = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/home/:id",
    component: Home,
  },
  {
    path: "/document",
    component: Document,
  },
  {
    path: "/document/:id",
    component: Document,
  },
  {
    path: "/assignment",
    component: Assignment,
  },
  {
    path: "/pt5",
    component: PT5,
  },
  {
    path: "blogs/cachtinhdiemphuongthuc5-phuongthucxettuyennhieutieuchidaihocbachkhoahcm",
    component: Howtocalculatepoints,
  },

  {
    path: "/learning/:id",
    component: Learning,
  },

  {
    path: "/course/:id",
    component: Course,
  },
];
const privateRoute = [];
export { publicRoute, privateRoute };
