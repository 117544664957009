import { useState, useEffect } from "react";
function useDebounce(value, delay) {
  const [publicValue, setPublicValue] = useState(value);
  useEffect(() => {
    let idTimer = setTimeout(() => {
      setPublicValue(value);
    }, delay);
    return () => clearTimeout(idTimer);
  }, [value]);
  return publicValue;
}
export default useDebounce;
