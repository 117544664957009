import classNames from "classnames/bind";
import styles from "./Howtocalculatepoints.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { faBook, faTimeline, faUsers } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../../../assets/images/facebook.png";
import Zalo from "../../../../assets/images/icon/zalo.png";
import youtube from "../../../../assets/images/icon/youtube.png";
import subject from "../../../../assets/images/bachkhoa.png";
import Button from "../../../Elements/Button/Button";

const cx = classNames.bind(styles);
function Howtocalculatepoints() {
  return (
    <div className={cx("container")}>
      <div>
        <h1>Xét tuyển kết hợp nhiều tiêu chí (phương thức 5) khóa 2024</h1>
        <span>
          Tác giả:{" "}
          <a href="https://www.facebook.com/profile.php?id=100086390823801">
            Nguyễn Quốc Vương
          </a>{" "}
          sinh viên K19 đã tốt nghiệp đại học Bách Khoa HCM, đang tiếp tục học
          Thạc Sĩ tại đây, bài viết dựa theo các thông tin chính thức từ trang
          chủ của trường Bách Khoa <a>https://hcmut.edu.vn/</a> được mình viết
          lại, mong rằng quý phụ huynh và các em học sẽ đọc dễ tiếp cận hơn.
          Thank you!!
        </span>
        <h2>Đối tượng</h2>
        <ul>
          <li>
            Tất cả các thí sinh có nguyện vọng đăng ký xét tuyển vào trường Đại
            Học Bách Khoa.
          </li>
          <li>
            Ngoại trừ các thí sinh đã từng là sinh viên trường Đại học Bách khoa
            - Đại học Quốc gia TPHCM đã bị kỷ luật thôi học trong thời gian 05
            năm (kể từ ngày đăng ký xét tuyển 2024) hoặc đã bị kỷ luật thôi học
            do thi hộ hoặc dùng các loại bằng cấp, chứng chỉ, giấy tờ giả mạo.
          </li>
        </ul>
        <h2>Quy định xét tuyển</h2>
        <span>
          Thí sinh được đánh giá kết hợp bao gồm ba thành tố và trọng số tương
          ứng được dùng để xét tuyển. Trong đó thành tố học lực chiếm 90%, thành
          tích cá nhân chiếm 5% và hoạt động xã hội, văn thể mỹ chiếm 5%. Cụ thể
          như sau:
        </span>
        <ul>
          <li>
            Tiêu chí học lực (90%), bao gồm 3 thành phần là điểm học tập ở bậc
            THPT (bao gồm 06 học kì ứng với tổ hợp đăng ký xét tuyển); điểm thi
            tốt nghiệp THPT (bao gồm các môn trong tổ hợp xét tuyển); điểm thi
            ĐGNL năm 2024.
          </li>
          <li>
            Tiêu chí thành tích cá nhân (5%): Học sinh đạt giải kỳ thi học sinh
            giỏi quốc gia, đạt giải KHKT, trình độ ngoại ngữ, chứng chỉ tuyển
            sinh quốc tế, thành viên trong đội tuyển học sinh giỏi quốc gia,
            tỉnh/thành phố, và các giải thưởng học thuật khác.
          </li>
          <li>
            Tiêu chí hoạt động xã hội, văn thể mỹ (5%): văn thể mỹ, các thành
            tích hoạt động xã hội khác.
          </li>
        </ul>

        <h2>Công thức tính điểm xét tuyển của Phương thức 5:</h2>
        <span className={cx("hightline")}>
          Điểm xét tuyển = (0.7 x Điểm ĐGNLquy đổi ) + (0.2 x Điểm thi TN THPT
          x3) + (0.1 x Điểm HL THPT) + Điểm thành tích các nhân<i> (nếu có)</i>{" "}
          + Điểm hoạt động xã hội<i> (nếu có)+ Điểm vùng(nếu có)</i>
        </span>
        <ul title="Trong đó:">
          <li>
            Điểm thi TN THPT: Tổng điểm thi 03 môn theo tổ hợp xét tuyển (tối đa
            30 điểm)
          </li>
          <li>
            Điểm HL THPT: Tổng điểm trung bình năm 03 môn theo tổ hợp xét tuyển
            trong 3 năm lớp 10, 11, 12 (tối đa 90 điểm)
          </li>
          <li></li>
        </ul>
        <span>Riêng đối với Điểm ĐGNLquy đổi</span>
        <ul>
          <li>
            Đối với thí sinh <span className={cx("hightline-black")}>Có</span>{" "}
            tham dự kỳ thi Đánh giá Năng lực của ĐHQG-HCM
          </li>
          <div className={cx("center-hightline")}>
            <span className={cx("hightline")}>
              ĐGNLquy đổi = Điểm thi ĐGNL x 90 / 990
            </span>
          </div>
          <li>
            Đối với thí sinh{" "}
            <span className={cx("hightline-black")}>KHÔNG</span> tham dự kỳ thi
            Đánh giá Năng lực của ĐHQG-HCM
          </li>
          <div className={cx("center-hightline")}>
            <span className={cx("hightline", { center: true })}>
              ĐGNLquy đổi = 0.75 x (Điểm thi TN THPT / 30 x 1200) x 90/990
            </span>
          </div>
        </ul>
        <a href="/pt5">Công cụ hỗ trợ tính điểm xét tuyển chuẩn xác năm 2024</a>
        <h3>Ví dụ 1: Trường hợp thi cả THPT và ĐGNL</h3>
        <span>Bạn Đạt thi khối A00 (Toán, Lý, Hóa) có điểm như sau:</span>
        <ul>
          <li>THPT 3 môn là 25 điểm (đã cộng vùng, xem quy định bên dưới)</li>
          <li>Điểm ĐGNL là 850 (đã cộng vùng, xem quy định bên dưới)</li>
          <li>
            Tổng điểm học bạ: Toán10 +Lý10 + Hóa10 +Toán11 +Lý11 + Hóa11 +
            Toán12 +Lý12 + Hóa12 là 80 điểm
          </li>
          <li>
            Không có điểm thành tích cá nhân hay hoạt động xã hội, điểm vùng
          </li>
        </ul>
        <span>
          Vì Đạt có thi ĐGNL nên điểm ĐGNLquy đổi = Điểm thi ĐGNL x 90 / 990 =
          850 x 90 / 990 = 77,27
        </span>
        <br></br>
        <span>
          Vậy điểm xét tuyển của bạn Đạt là: (0.7 x 77,27 ) + (0.2 x 25 x3) +
          (0.1 x 80) = 78,889
        </span>
        <h3>Ví dụ 2: Trường hợp chỉ thi THPT mà không thi ĐGNL</h3>
        <span>Bạn Liên thi khối A00 (Toán, Lý, Hóa) có điểm như sau:</span>
        <ul>
          <li>THPT 3 môn là 25 điểm(đã cộng vùng)</li>
          <li>Không thi ĐGNL </li>
          <li>
            Tổng điểm học bạ: Toán10 +Lý10 + Hóa10 +Toán11 +Lý11 + Hóa11 +
            Toán12 +Lý12 + Hóa12 là 80 điểm
          </li>
          <li>
            Không có điểm thành tích cá nhân hay hoạt động xã hội, điểm vùng
          </li>
        </ul>
        <span>
          Vì Liên không thi ĐGNL nên điểm ĐGNLquy đổi = 0.75 x (Điểm thi TN THPT
          / 30 x 1200) x 90/990 = 0.75 x (25 / 30 x 1200) x 90/990 = 68,182
        </span>
        <br></br>
        <span>
          Vậy điểm xét tuyển của bạn Liên là: (0.7 x 68,182 ) + (0.2 x 25 x3) +
          (0.1 x 80) = 70,73
        </span>
        <br></br>
        <br></br>
        <span>
          <span className={"hightline-black"}>Nhận xét</span>: Rõ ràng với 2 ví
          dụ trên thì nếu bạn không thi ĐGNL là một thiệt thòi rất lớn. Vì thế
          hãy đăng kí thi ĐGNL nếu còn muốn trở thành 1 BKer
        </span>
        <h3>Cộng Đồng Hỗ Trợ Giải Đáp Thắc Mắc</h3>
        <div className={cx("Group")}>
          <div className={cx("sub-group")}>
            <a
              href="https://youtu.be/Sg905e5sL_E"
              target="_blank"
              className={cx("logo-container")}
            >
              <img className={cx("logo")} src={youtube}></img>
              <h2 className={cx("header-title")}>
                Video hướng dẫn cách tính năm 2024
              </h2>
            </a>
          </div>
          <div className={cx("sub-group")}>
            <a
              href="https://www.facebook.com/groups/hcmutcncp"
              target="_blank"
              className={cx("logo-container")}
            >
              <img className={cx("logo")} src={Logo}></img>
              <h2 className={cx("header-title")}>
                Nhóm facebook hỏi đáp tuyển sinh
              </h2>
            </a>
          </div>
          <div className={cx("sub-group")}>
            <a
              href="https://zalo.me/g/mwduif387"
              target="_blank"
              className={cx("logo-container")}
            >
              <img className={cx("logo")} src={Zalo}></img>
              <h2 className={cx("header-title")}>
                Nhóm Zalo hỏi đáp tuyển sinh 2024
              </h2>
            </a>
          </div>
        </div>
        <h2>Ngưỡng đảm bảo chất lượng đầu vào:</h2>
        <ul>
          <li>Điểm thi Đánh giá Năng lực của ĐHQG-HCM (ĐGNL): 600 điểm</li>
          <li>
            Điểm thi Tốt nghiệp Trung học Phổ thông (TN THPT): 18 điểm (theo tổ
            hợp xét tuyển)
          </li>
        </ul>
        <h2>Quy định cộng điểm vùng</h2>
        <h5>Quy định điểm cộng THPT</h5>
        <ul>
          <li> Khu vực 1 (KV1) cộng 0,75 điểm.</li>
          <li> Khu vực 2 nông thôn (KV2-NT) cộng 0,5 điểm.</li>
          <li> Khu vực 2 (KV2) là 0,25 điểm.</li>
          <li> Khu vực 3 (KV3) không được tính điểm ưu tiên.</li>
        </ul>
        <h5>Quy định điểm cộng ĐGNL</h5>
        <ul>
          <li> Khu vực 1 (KV1) cộng 30 điểm.</li>
          <li> Khu vực 2 nông thôn (KV2-NT) cộng 20 điểm.</li>
          <li> Khu vực 2 (KV2) là 10 điểm.</li>
          <li> Khu vực 3 (KV3) không được tính điểm ưu tiên.</li>
        </ul>
        <h2>Lưu ý:</h2>
        <ul>
          <li>
            Thí sinh chỉ sử dụng kết quả thi Tốt nghiệp Trung học Phổ thông và
            Đánh giá Năng lực của ĐHQG-HCM của năm tuyển sinh
          </li>
          <li>
            Các cột điểm thành phần của thí sinh không được thấp hơn mức ngưỡng
            đảm bảo đầu vào do nhà trường quy định (điểm sàn).
          </li>
          <li>
            Trường hợp ngành/nhóm ngành có nhiều tổ hợp môn xét tuyển, hệ thống
            của Bộ GD&ĐT sẽ tự động chọn tổ hợp môn đạt tổng điểm cao nhất trong
            các tổ hợp của ngành xét tuyển.
          </li>
          <li>
            Trường hợp các ngành được tuyển sinh chung trong cùng một nhóm
            ngành: Điểm xét tuyển là giống nhau cho các ngành trong cùng một
            nhóm ngành. Sinh viên sẽ được phân ngành từ năm thứ 2 theo nguyện
            vọng và kết quả học tập.
          </li>
          <li>
            Tiêu chí phụ để xét tuyển: Trường hợp có quá nhiều thí sinh cùng mức
            điểm xét tuyển và số thí sinh vượt quá chỉ tiêu, thì đối với ngành
            Quản lý Công nghiệp và chương trình CLC/TT, thí sinh có điểm môn
            Tiếng Anh cao hơn sẽ được trúng tuyển. Đối với các ngành còn lại thí
            sinh có điểm môn Toán cao hơn sẽ được trúng tuyển.
          </li>
        </ul>
        <span>
          Trường hợp các nhóm ngành/ngành có môn tiếng Anh trong tổ hợp môn xét
          tuyển: Chỉ sử dụng kết quả bài thi tiếng Anh và bảng quy đổi điểm từ
          Chứng chỉ tiếng Anh quốc tế IELTS, TOEFL iBT và TOEIC 4 kỹ năng của
          nhà trường, không sử dụng kết quả miễn thi bài thi tiếng Anh của kì
          thi tốt nghiệp THPT 2024
        </span>
        <ul>
          <li>
            HĐTS sẽ quyết định tuyển thẳng các trường hợp thí sinh xuất sắc
            trong một tiêu chí/lĩnh vực (thí sinh đạt giải cao trong các kì thi
            HSG Quốc tế, Quốc gia, có điểm cao vượt trội trong khi thi THPT và
            ĐGNL, hoặc xuất sắc trong hoạt động thể thao, văn thể mỹ)
          </li>
        </ul>
        <span>
          Đối với thí sinh dự tuyển các chương trình Giảng dạy bằng tiếng Anh,
          chương trình Tiên tiến, chương trình Chuyển tiếp Quốc tế sang Úc, New
          Zealand, thí sinh phải đạt điều kiện tiếng anh sơ tuyển IELTS ≥ 4.5/
          TOEFL iBT ≥ 34/ TOEIC nghe-đọc ≥ 400 & nói-viết ≥ 200/ Duolingo
          English Test (DET) ≥ 65/ Linguaskill ≥ 153/ PTE ≥ 28/ PET ≥ 153/ FCE ≥
          153/ CAE ≥ 153.
        </span>
      </div>
      <div className={cx("advertisement-desktop")}>
        <div className={cx("link-intro")}>
          <h3>CÁC CHỦ ĐỀ ĐÁNG CHÚ Ý</h3>
          <div>
            <Button
              link={"/home/250"}
              pt5
              text={"Thông Lớp Đại Cương"}
            ></Button>
            <Button
              link={"https://zalo.me/g/mwduif387"}
              blank
              pt5
              text={"Nhóm Zalo"}
            ></Button>
            <Button link={"/home/300"} pt5 text={"Tài Liệu Các Khoa"}></Button>
            <Button
              link={"document/290"}
              pt5
              text={"Khóa học miễn phí"}
            ></Button>
          </div>
        </div>
        <div className={cx("container-advertisement")}>
          <div className={cx("container-image")}>
            <img className={cx("image")} src={subject}></img>
          </div>
          <h5>Nhóm chia sẻ tài liệu ở đại học</h5>
          <Button
            link={"https://www.facebook.com/groups/hcmutcncp"}
            text={"Xem nhóm"}
          ></Button>
          <div className={cx("detail-advertisement")}>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faUsers}
              ></FontAwesomeIcon>
              <span> Sinh viên Bách Khoa</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faFacebook}
              ></FontAwesomeIcon>
              <span> Nhóm học tập lớn nhất Bách Khoa</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faBook}
              ></FontAwesomeIcon>
              <span> Chia sẻ tài liệu free</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faTimeline}
              ></FontAwesomeIcon>
              <span> Anh chị nhiệt tình</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faNewspaper}
              ></FontAwesomeIcon>
              <span> Tài liệu mới&chất lượng</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Howtocalculatepoints;
