import classNames from "classnames/bind";
import styles from "./Notification.module.scss";
import { useEffect, useState } from "react";
const cx = classNames.bind(styles);
function Notification() {
  const [animation, setAnimation] = useState(false);
  const [text, setText] = useState(
    "+1 vừa bạn tham gia nhóm zalo tuyển sinh 2024 😍"
  );
  useEffect(() => {
    const id1 = setTimeout(() => {
      setText("+1 vừa bạn đăng ký sớm lớp giải tích 1 😍");
      setAnimation(true);
    }, 15000);
    const id3 = setTimeout(() => {
      setText("+1 vừa bạn tham gia nhóm fb tuyển sinh 2024😍");
      setAnimation(true);
    }, 60000);
    const id4 = setTimeout(() => {
      setText("+1 vừa bạn đăng ký combo giải tích 1 + đại số tt😍");
      setAnimation(true);
    }, 2 * 60 * 1000);
    const id2 = setTimeout(() => {
      setText("+1 vừa bạn đăng ký lớp xác suất thống kê 😍");
      setAnimation(true);
    }, 3 * 60 * 1000);

    const idTimer = setInterval(() => {
      setText("+1 vừa bạn đăng ký lớp giải tích 1 😍");
      setAnimation((pre) => {
        return !pre;
      });
    }, 5 * 60 * 1000);
    return () => {
      clearInterval(idTimer);
    };
  }, []);
  return (
    <div className={cx("wrapper", { animation: animation })}>
      <span>{text}</span>
    </div>
  );
}

export default Notification;
