import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import "./Slider.css";
import avatar from "../../../assets/images/avater.jpg";
import zalo from "../../../assets/images/zalo.png";
import facebook from "../../../assets/images/gr.png";
import page from "../../../assets/images/page.png";
import tiktok from "../../../assets/images/titktok_slider.png";
import youtube from "../../../assets/images/youtube_silder.png";
import Button from "../Button/Button";
import mb_gr from "../../../assets/images/mb_group.png";
//avatar
import duykhoa from "../../../assets/images/Slider/duykhoa.jpg";
import bao from "../../../assets/images/Slider/bao.jpg";
import doan from "../../../assets/images/Slider/doan.jpg";
import nhu from "../../../assets/images/Slider/nhu.jpg";

import trang from "../../../assets/images/Slider/trang.jpg";
function Slider() {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const idTimer = setInterval(() => {
      setCount((pre) => {
        return pre === 3 ? 0 : pre + 1;
      });
    }, 10000);
    return () => {
      clearInterval(idTimer);
    };
  }, []);
  const handleDown = () => {
    setCount((pre) => {
      return pre === 0 ? 0 : pre - 1;
    });
  };
  const handleUp = () => {
    setCount((pre) => {
      return pre === 3 ? 0 : pre + 1;
    });
  };
  return (
    <div class="content-slider">
      <div class="slider">
        <div class="slides">
          <input
            type="radio"
            name="radio-btn"
            id="radio1"
            checked={0 === count}
          />
          <input
            type="radio"
            name="radio-btn"
            id="radio2"
            checked={1 === count}
          />
          <input
            type="radio"
            name="radio-btn"
            id="radio3"
            checked={2 === count}
          />
          <input
            type="radio"
            name="radio-btn"
            id="radio4"
            checked={3 === count}
          />

          {/* slide 1 */}
          <div class="contain-slide contain-slide-first">
            <div class="display-mobile slide1">
              <div>
                <h3>Tham gia group học tập Bách Khoa CNCP</h3>
                <span>
                  Nơi trao đổi, hỏi bài chia sẽ tài liệu giữa các Sinh Viên Bách
                  Khoa HCM
                </span>
                <div class="bt-mobile">
                  <Button
                    story={true}
                    link={"https://www.facebook.com/groups/495364634751426"}
                    text={"Tham gia"}
                    blank={true}
                  ></Button>
                </div>
              </div>
              <div>
                <img src={mb_gr}></img>
              </div>
            </div>
            <div class="slide first display">
              <div>
                <img src={zalo}></img>
                <div>
                  <h3>Nhóm zalo HCMUT-CNCP</h3>
                  <span>
                    Nơi hỏi thủ tục nhập học, sau này thành group chia sẻ kiến
                    thức đại cương
                  </span>
                  <div class="bt">
                    <Button
                      blank={true}
                      link={"https://zalo.me/g/mwduif387"}
                      story={true}
                      text={"Tham gia"}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide second display">
              <div>
                <img src={facebook}></img>
                <div>
                  <h3>Nhóm facebook HCMUT-CNCP</h3>
                  <span>
                    Nơi trao đổi, chia sẽ tài liệu, xem livestream dạy đại cương
                    của anh Vương
                  </span>
                  <div class="bt">
                    <Button
                      blank={true}
                      link={"https://www.facebook.com/groups/495364634751426"}
                      story={true}
                      text={"Tham gia"}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide third display">
              <div>
                <img src={page}></img>
                <div>
                  <h3>Fanpage học tập HCMUT-CNCP</h3>
                  <span>
                    Nơi share các tài liệu, đại cương chuyên ngành cho sinh viên
                    Bách Khoa
                  </span>
                  <div class="bt">
                    <Button
                      blank={true}
                      link={
                        "https://www.facebook.com/vuong.nguyenking.WebGIAODUC"
                      }
                      story={true}
                      text={"Theo dõi"}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* slide 2 */}
          <div class="contain-slide">
            <div class="display-mobile slide2">
              <div>
                <h3>Feedback Lớp Giải Tích 1</h3>
                <span>SV Đinh Duy Khoa</span>
                <br></br>
                <span>
                  <i>
                    "Lớp quá chất lượng ạ, nội dung tóm tắt, có file bài tập để
                    mình luyện tập nhờ đó mà em được 10đ giải tích 1 ạ."
                  </i>
                </span>
                <div>
                  <Button
                    link={
                      "https://www.facebook.com/profile.php?id=100086390823801"
                    }
                    blank={true}
                    story={true}
                    text={"Tham gia"}
                  ></Button>
                </div>
              </div>
              <div>
                <img src={mb_gr}></img>
              </div>
            </div>
            <div class="slide first display">
              <div>
                <div>
                  <img class="img-avatar" src={duykhoa}></img>
                  <span>SV Đinh Duy Khoa</span>
                </div>

                <div>
                  <h3>Feedback Lớp Giải Tích 1</h3>
                  <span>
                    <i>
                      {" "}
                      "Lớp quá chất lượng ạ, nội dung tóm tắt, mỗi buổi học đều
                      có file bài tập để mình có thể luyện tập nhờ đó mà em được
                      10 đ thi giải tích 1 ạ"
                    </i>
                  </span>
                  <div class="bt feedback">
                    <Button
                      blank={true}
                      link={
                        "https://www.facebook.com/profile.php?id=100086390823801"
                      }
                      story={true}
                      text={"Tham gia"}
                    ></Button>
                  </div>
                  <div className="star">
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide second display">
              <div>
                <div>
                  <img class="img-avatar" src={bao}></img>
                  <span>SV Lê Trần Gia Bảo OISP</span>
                </div>

                <div>
                  <h3>Feedback Lớp Giải Tích 2</h3>
                  <span>
                    <i>
                      {" "}
                      "Học anh Vương gt1 và gt2 rất dễ hiểu. Ngoài ra, a Vương
                      còn hướng dẫn môn khác ví dụ như ĐSTT hồi HK1. Học a auto
                      pass môn điểm cao"
                    </i>
                  </span>
                  <div class="bt feedback">
                    <Button
                      blank={true}
                      link={
                        "https://www.facebook.com/profile.php?id=100086390823801"
                      }
                      story={true}
                      text={"Tham gia"}
                    ></Button>
                  </div>
                  <div className="star">
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide third display">
              <div>
                <div>
                  <img class="img-avatar" src={trang}></img>
                  <span>SV Trần Huyền Trang</span>
                </div>
                <div>
                  <h3>Feedback Lớp Hóa Đại Cương</h3>
                  <span>
                    <i>
                      {" "}
                      "Lớp hóa xịn nha a, em bị ghiền nghe giọng anh nên học hết
                      mấy môn của anh luôn, hóa điểm em được 9.8 và nhờ đó phân
                      ngành đúng ngành em thích nữa"
                    </i>
                  </span>
                  <div class="bt feedback">
                    <Button
                      blank={true}
                      story={true}
                      link={
                        "https://www.facebook.com/profile.php?id=100086390823801"
                      }
                      text={"Tham gia"}
                    ></Button>
                  </div>
                  <div className="star">
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* slide 3 */}
          <div class="contain-slide">
            <div class="display-mobile slide3">
              <div>
                <h3>Tham gia nhóm chat Zalo đại cương CNCP</h3>
                <span>
                  Nơi hỏi thủ tục nhập học, sau này thành group chia sẻ kiến
                  thức đại cương.
                </span>
                <div>
                  <Button
                    link={"https://zalo.me/g/mwduif387"}
                    blank={true}
                    story={true}
                    text={"Tham gia"}
                  ></Button>
                </div>
              </div>
            </div>
            <div class="slide first first1 display">
              <div>
                <img src={facebook}></img>
                <div>
                  <h3>Page Tech Học Để Đi Làm</h3>
                  <span>
                    Nơi chia sẻ các kiến thức chuyên ngành vượt ra ngoài các
                    kiến thức đại cương.
                  </span>
                  <div class="bt">
                    <Button
                      link={
                        "https://www.facebook.com/profile.php?id=100091514363652"
                      }
                      story={true}
                      blank={true}
                      text={"Theo dõi"}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide second1 display">
              <div>
                <img src={youtube}></img>
                <div>
                  <h3>Kênh Youtube CNCP</h3>
                  <span>
                    Chia sẽ các video về đại cương, chuyên ngành, lâu lâu
                    livestream giải đề và chém gió.
                  </span>
                  <div class="bt">
                    <Button
                      blank={true}
                      link={"https://www.youtube.com/@cncp246"}
                      story={true}
                      text={"Tham gia"}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide third1 display">
              <div>
                <img src={tiktok}></img>
                <div>
                  <h3>Kênh Tiktok CNCP</h3>
                  <span>
                    Nơi chia sẻ các video ngăn về chuyên nghiệp, vui vẻ gợi cảm
                    hứng học tập
                  </span>
                  <div class="bt">
                    <Button
                      blank={true}
                      link={"https://www.tiktok.com/@hotrohoctapsinhvien"}
                      story={true}
                      text={"Theo dõi"}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* slide 4*/}
          <div class="contain-slide">
            <div class="display-mobile slide4">
              <div>
                <h3>Feedback Lớp Hóa Đại Cương</h3>
                <span>SV Nguyễn Thị Trang OISP</span>
                <br></br>
                <span>
                  <i>
                    "Em học anh cả calculus 1 và hóa điểm siêu cao luôn 9.8 với
                    10, được chọn làm sinh viên tiêu biểu của khoa ạ"
                  </i>
                </span>
                <div>
                  <Button
                    link={
                      "https://www.facebook.com/profile.php?id=100086390823801"
                    }
                    blank={true}
                    story={true}
                    text={"Tham gia"}
                  ></Button>
                </div>
              </div>
            </div>
            <div class="slide first1 display">
              <div>
                <div>
                  <img class="img-avatar" src={nhu}></img>
                  <span>SV Nguyễn Thị Trang OISP</span>
                </div>

                <div>
                  <h3>Feedback Lớp Hóa Đại Cương</h3>
                  <span>
                    <i>
                      {" "}
                      "Anh vừa dạy hay lại nhiệt tình, em học anh cả calculus 1
                      và 2 điểm siêu cao luôn 9.8 với 10, được chọn làm sinh
                      viên tiêu biểu của khoa hehe"
                    </i>
                  </span>
                  <div class="bt feedback">
                    <Button
                      blank={true}
                      link={
                        "https://www.facebook.com/profile.php?id=100086390823801"
                      }
                      story={true}
                      text={"Tham gia"}
                    ></Button>
                  </div>
                  <div className="star">
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide second1 display">
              <div>
                <div>
                  <img class="img-avatar" src={bao}></img>
                  <span>SV Lê Trần Gia Bảo OISP</span>
                </div>

                <div>
                  <h3>Feedback Lớp Giải Tích 2</h3>
                  <span>
                    <i>
                      {" "}
                      "Học anh Vương gt1 và gt2 rất dễ hiểu. Ngoài ra, a Vương
                      còn hướng dẫn môn khác ví dụ như ĐSTT hồi HK1. Học a auto
                      pass môn điểm cao"
                    </i>
                  </span>
                  <div class="bt feedback">
                    <Button
                      blank={true}
                      link={
                        "https://www.facebook.com/profile.php?id=100086390823801"
                      }
                      story={true}
                      text={"Tham gia"}
                    ></Button>
                  </div>
                  <div className="star">
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide third1 display">
              <div>
                <div>
                  <img class="img-avatar" src={doan}></img>
                  <span>SV Nguyễn Văn Đoàn</span>
                </div>
                <div>
                  <h3>Feedback Lớp Giải Tích 1</h3>
                  <span>
                    <i>
                      {" "}
                      "Anh ơi, em mới check điểm gt2, em tổng kết trên 9. Em cảm
                      ơn anh rất nhiều. Nhờ học anh gt1 và gt2 mà điểm em cao
                      hơn kì vọng rất nhiều, em cảm ơn anh ạ!!!"
                    </i>
                  </span>
                  <div class="bt feedback">
                    <Button
                      link={
                        "https://www.facebook.com/profile.php?id=100086390823801"
                      }
                      blank={true}
                      story={true}
                      text={"Tham gia"}
                    ></Button>
                  </div>
                  <div className="star">
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar}></FontAwesomeIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={handleDown} class="bt-down display-mobile-bt">
        <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
      </div>
      <div onClick={handleUp} class="bt-up display-mobile-bt">
        <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
      </div>
      <div class="navigation-manual">
        <label
          for="radio1"
          onClick={() => setCount(0)}
          class={0 === count ? "manual-btn active" : "manual-btn"}
        ></label>
        <label
          for="radio2"
          onClick={() => setCount(1)}
          class={1 === count ? "manual-btn active" : "manual-btn"}
        ></label>
        <label
          for="radio3"
          onClick={() => setCount(2)}
          class={2 === count ? "manual-btn active" : "manual-btn"}
        ></label>
        <label
          for="radio4"
          onClick={() => setCount(3)}
          class={3 === count ? "manual-btn active" : "manual-btn"}
        ></label>
      </div>
    </div>
  );
}

export default Slider;
