import classNames from "classnames/bind";
import styles from "./Button.module.scss";
const cx = classNames.bind(styles);
function Button({
  text,
  link,
  story,
  cal,
  onclick,
  pt5,
  width,
  blank,
  children,
  opacityText,
  height,
}) {
  return (
    <div onClick={onclick} className={cx("button")}>
      <div className={cx("ani")}>{children}</div>
      <a
        target={blank && "_blank"}
        className={cx("link", {
          story: story,
          cal,
          pt5,
          width,
          opacityText,
          height,
        })}
        href={link}
      >
        {text}
      </a>
    </div>
  );
}

export default Button;
