import classNames from "classnames/bind";
import styles from "./Footer.module.scss";
import Logo from "../../../assets/images/facebook.png";
import facebook from "../../../assets/images/facebook.png";
import youtube from "../../../assets/images/youtube.png";
import tiktok from "../../../assets/images/tiktok.png";
import dmca from "../../../assets/images/dmca.png";
const cx = classNames.bind(styles);
function Footer() {
  return (
    <div className={cx("wrapper")}>
      <div className={cx("infor")}>
        <div className={cx("list-footer")}>
          <a
            href="https://www.facebook.com/groups/495364634751426"
            target="_blank"
            className={cx("logo-container")}
          >
            <img className={cx("logo")} src={Logo}></img>
            <h2 className={cx("header-title")}>Trang học Tập HCMUT-CNCP</h2>
          </a>
          <div>
            <span>Điện thoại: 0397755317</span>
          </div>
          <div>
            <span>Email: vuong.nguyenquoc.tech@gmail.com</span>
          </div>
          <div>
            <span>Địa chỉ: Làng Đại Học, Đông Hòa, Dĩ An, Bình Dương</span>
          </div>
        </div>
        <div className={cx("list-footer")}>
          <div>
            <h2 className={cx("header-title")}>Nội Dung</h2>
          </div>
          <div>
            <span>Cung cấp khóa học đại cương</span>
          </div>
          <div>
            <span>Chia sẻ tài liệu Bách Khoa</span>
          </div>
          <div>
            <span>Chia sẻ khó khăn học tập</span>
          </div>
        </div>
        <div className={cx("list-footer")}>
          <div>
            <h2 className={cx("header-title")}>Các Lớp Dạy</h2>
          </div>
          <div>
            <span>Các lớp đại cương Bách Khoa</span>
          </div>
          <div>
            <span>Lớp lập trình...</span>
          </div>
          <div>
            <span>Lớp chuyên nghành</span>
          </div>
        </div>
        <div className={cx("list-footer")}>
          <div>
            <h2 className={cx("header-title")}>Mục tiêu tương lai</h2>
          </div>
          <div>
            <span>Phát triển cộng đồng học tập</span>
          </div>
          <div>
            <span>Hoạt động chuyên nghiệp</span>
          </div>
          <div>
            <span>Tổ chức uy tín</span>
          </div>
        </div>
      </div>
      <div className={cx("copyright-container")}>
        <div className={cx("copyright")}>
          <span>
            © 2019 - 2023 HCMUT-CNCP. Nền tảng học hàng đầu Việt Nam cho sinh
            viên Bách Khoa và Việt Nam
          </span>
          <div className={cx("channel")}>
            <a href="https://www.youtube.com/@cncp246">
              <img src={dmca}></img>
            </a>
            <a href="https://www.youtube.com/@cncp246">
              <img src={youtube}></img>
            </a>
            <a href="https://www.facebook.com/groups/495364634751426">
              <img src={facebook}></img>
            </a>
            <a href="https://www.tiktok.com/@hotrohoctapsinhvien">
              <img src={tiktok}></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
