import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookQuran,
  faCalculator,
  faHouse,
  faRightToBracket,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./SlidebarMobile.module.scss";
import MenuItemMobile from "./MenuItemMobile/MenuItemMobile";
const cx = classNames.bind(styles);
function SlidebarMobile({ onclick, active }) {
  return (
    <>
      <div
        onClick={() => {
          onclick(false);
        }}
        className={cx("layout", { active: active })}
      ></div>
      <div className={cx("container", { container_active: active })}>
        <nav className={cx("wrapper")}>
          <MenuItemMobile
            onclick={() => {
              onclick(false);
            }}
            to={"/"}
            title={"Đăng xuất"}
            icon={
              <FontAwesomeIcon
                fontSize={"2rem"}
                color="#757575"
                icon={faRightToBracket}
              ></FontAwesomeIcon>
            }
          ></MenuItemMobile>
          <div className={cx("seperater")}></div>
          <MenuItemMobile
            onclick={() => {
              onclick(false);
            }}
            to={"/"}
            title={"Bách Khoa"}
            icon={
              <FontAwesomeIcon
                fontSize={"1.8rem"}
                color="#757575"
                icon={faHouse}
              ></FontAwesomeIcon>
            }
          ></MenuItemMobile>
          <MenuItemMobile
            to={"/document"}
            title={"Tài liệu"}
            onclick={() => {
              onclick(false);
            }}
            icon={
              <FontAwesomeIcon
                fontSize={"1.8rem"}
                color="#757575"
                icon={faBookQuran}
              ></FontAwesomeIcon>
            }
          ></MenuItemMobile>
          <MenuItemMobile
            to={"/assignment"}
            title={"Bài tập lớn"}
            onclick={() => {
              onclick(false);
            }}
            icon={
              <FontAwesomeIcon
                fontSize={"1.8rem"}
                color="#757575"
                icon={faStar}
              ></FontAwesomeIcon>
            }
          ></MenuItemMobile>
          <MenuItemMobile
            to={"/pt5"}
            title={"Tính điểm PT5"}
            onclick={() => {
              onclick(false);
            }}
            icon={
              <FontAwesomeIcon
                fontSize={"1.8rem"}
                color="#757575"
                icon={faCalculator}
              ></FontAwesomeIcon>
            }
          ></MenuItemMobile>
        </nav>
      </div>
    </>
  );
}

export default SlidebarMobile;
