import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gt1 from "../../../assets/images/Course/gt1.png";
import Star from "../../../assets/images/star.png";
import styles from "./Card.module.scss";
import { faClock, faUsers } from "@fortawesome/free-solid-svg-icons";
const cx = classNames.bind(styles);
function Card({
  image,
  title,
  price,
  oldPrice,
  member,
  linkRegister,
  lesson,
  video,
  document,
  link,
  tutor,
  blank,
  soon,
  discountText = "(Giá đăng kí sớm)",
}) {
  return (
    <div className={cx("wrapper")}>
      <div>
        <a
          href={soon || link}
          target={blank ? "_blank" : ""}
          className={cx("container-image")}
        >
          <img className={cx("image")} src={image || gt1}></img>
          {soon || (
            <div className={cx("image-active")}>
              <button className={cx("bt-course")}>
                {video ? "Xem video" : price ? "Xem lớp học" : "Xem tài liệu"}
              </button>
            </div>
          )}

          {price && !soon && (
            <div className={cx("star-container")}>
              <img src={Star} className={cx("star")}></img>
              {/* <FontAwesomeIcon
                className={cx("star")}
                icon={faStar}
              ></FontAwesomeIcon> */}
            </div>
          )}
        </a>
      </div>
      <h2 className={cx("name-title")}>
        <a className={cx("name-course")}>{title}</a>
        {video || document || (
          <span className={cx("name-course")}>{tutor}</span>
        )}
      </h2>
      {video || soon || document || (
        <div className={cx("price")}>
          <div>
            <div className={cx("thongtin")}>
              <div>
                <FontAwesomeIcon
                  fontSize="1.4rem"
                  color="#666"
                  icon={faUsers}
                ></FontAwesomeIcon>
                <span>{member}</span>
              </div>
              <div>
                <FontAwesomeIcon
                  fontSize="1.4rem"
                  color="#666"
                  icon={faClock}
                ></FontAwesomeIcon>
                <span>{lesson} buổi</span>
              </div>
              <div>
                <a target="blank" href={linkRegister}>
                  Đăng ký{" "}
                </a>
              </div>
            </div>
            <span className={cx("discount")}>
              <i>{discountText}. Tặng 100K khi mua combo 2 môn</i>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Card;
