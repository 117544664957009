import classNames from "classnames/bind";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faFacebook, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  faCameraRetro,
  faCheck,
  faMinus,
  faTimeline,
} from "@fortawesome/free-solid-svg-icons";
import subject from "../../../assets/images/Course/all.png";
import Subject from "../../Elements/Subject/Subject";
import styles from "./Learning.module.scss";
import Button from "../../Elements/Button/Button";
const cx = classNames.bind(styles);
function Learning() {
  const DataCourseBasic = [
    {
      name: "Giải Tích 1",
      path: "https://drive.google.com/open?id=1ZRInaJqVYsID84yflam0dZ4LxEVMrj9h&usp=drive_copy",
    },
    {
      name: "Giải Tích 2",
      path: "https://drive.google.com/open?id=1tWf4L6YVZqd2uiVwEmczD4y8BQnGC_Ab&usp=drive_copy",
    },
    {
      name: "Vật Lý 1",
      path: "https://drive.google.com/open?id=1grP3wlzFiP31l0M6QgpjXUDPyhQad0Js&usp=drive_copy",
    },
    {
      name: "Vật Lý 2",
      path: "https://drive.google.com/open?id=1L8KjLqYA_WzgL8Ek3zDNaxsjQfktKrg3&usp=drive_copy",
    },
    {
      name: "Đại Số Tuyến Tính",
      path: "https://drive.google.com/open?id=11qzguogWdOv6LYGKoE_V0TIdbCZS3cbt&usp=drive_copy",
    },
    {
      name: "Hóa Đại Cương",
      path: "https://drive.google.com/open?id=1cOQs7HhTmcFY2mk3PKyzEBEDNSEihpC-&usp=drive_copy",
    },
    {
      name: "Phương Pháp Tính",
      path: "https://drive.google.com/open?id=1tf-T7tYcdUh8eitAbfNmeDErTwu6Qe6S&usp=drive_copy",
    },

    {
      name: "Thí nghiệm Vật Lý 1",
      path: "https://drive.google.com/open?id=1KwqD6331yovdZ2Reo6RbhB62qiGg0ON0&usp=drive_copy",
    },
    {
      name: "Xác suất thống kê",
      path: "https://drive.google.com/open?id=1me1w6xCLmHf0ihY2NVgLU0zLQimWsQVm&usp=drive_copy",
    },
  ];
  const DataCourseSociety = [
    {
      name: "Triết Học Mac-Lenin",
      path: "https://drive.google.com/open?id=1bCoZKl3zXQTtaDDlNPVMkIWoBfrRa77B&usp=drive_copy",
    },
    {
      name: "Pháp Luật Đại Cương",
      path: "https://drive.google.com/open?id=1qDdLdURLnTVJETSjTzNYmMFanXibDAXZ&usp=drive_copy",
    },
    {
      name: "Kinh Tế Chính Trị",
      path: "https://drive.google.com/open?id=1m_GMMdId5KUb26a_3aCo3Eh48N3e6Ury&usp=drive_copy",
    },
    {
      name: "Chủ Nghĩa Khoa Học",
      path: "https://drive.google.com/open?id=1wET1tWtt-FtfBrrK7XFXZo6zV_AuFhOg&usp=drive_copy",
    },
    {
      name: "Lịch Sử Đảng",
      path: "https://drive.google.com/open?id=17hq5oRODX55tGhGaMGtuL2Zkzi-3BLao&usp=drive_copy",
    },
    {
      name: "Tư tưởng Hồ Chí Minh",
      path: "https://drive.google.com/open?id=1nvBgGVnLaj6WH0qZw5wPn1op660F64Es&usp=drive_copy",
    },
  ];
  const DataCourseAdvance = [
    {
      name: "Vật Lý Bán dẫn",
      path: "https://drive.google.com/open?id=1oxkzMpkPojbA-o4LqsxTpZ0e9b3T5zQs&usp=drive_copy",
    },
    {
      name: "Mạch Điện Tử",
      path: "https://drive.google.com/open?id=1OLiC_KHVAgPRIYbJBjfkWARmgcZF5-re&usp=drive_copy",
    },
    {
      name: "Hệ Thống Máy tính Và Ngôn Ngữ Lập Trình",
      path: "https://drive.google.com/open?id=1Oolst9NUtHfohxV38lllUGjewi9pWAXu&usp=drive_copy",
    },
    {
      name: "Trường điện từ",
      path: "https://drive.google.com/open?id=1MDQpjVXwu_Wymu-eth5vskUJuPu_aiIF&usp=drive_copy",
    },
    {
      name: "Giải Tích Mạch",
      path: "https://drive.google.com/open?id=188T347dGjQf0BZON1TsDtvq9tVCzh8jy&usp=drive_copy",
    },
    {
      name: "Kỹ Thuật Số",
      path: "https://drive.google.com/open?id=17FSw-O0WakFRBx02ZeRNfMRPuqZLR6l3&usp=drive_copy",
    },
    {
      name: "Mạch Điện Tủ",
      path: "https://drive.google.com/open?id=1OLiC_KHVAgPRIYbJBjfkWARmgcZF5-re&usp=drive_copy",
    },
    {
      name: "Cơ Sở Kỹ Thuật Điện",
      path: "https://drive.google.com/open?id=1ysTAeKzdxt29FGv_truHyuYA_NL4t3B3&usp=drive_copy",
    },
    {
      name: "Vi Xử Lý",
      path: "https://drive.google.com/open?id=1MhSFOH_2e0rmYOkR-l7djcSC1J2eboe0&usp=drive_copy",
    },
    {
      name: "Tín Hiệu Và Hệ Thống",
      path: "https://drive.google.com/open?id=1bpyQBFYDd47UmUjpk1oABit5yEe8Uj4T&usp=drive_copy",
    },
    {
      name: "Đo Lường Công Nghiệp",
      path: "https://drive.google.com/open?id=1t_VzboxkfCY9skaEqJrXR0TcZMVR9u_K&usp=drive_copy",
    },
    {
      name: "Cơ Sở Điện Tử Công Suất",
      path: "https://drive.google.com/open?id=1L6L1hnwUfMNJfuvPFhPB7Zx7-4heaLvr&usp=drive_copy",
    },
    {
      name: "Cơ sở điều khiển tự động",
      path: "https://drive.google.com/open?id=14_H6Ef2v_Ne7jdv2bhnh9l44ihhjtAMn&usp=drive_copy",
    },
    {
      name: "Đo lường công nghiệp",
      path: "https://drive.google.com/open?id=1t_VzboxkfCY9skaEqJrXR0TcZMVR9u_K&usp=drive_copy",
    },
    {
      name: "Đo lường máy tính",
      path: "https://drive.google.com/open?id=15Re9waB_ZG9LEmfxk0cws27EVbIketuw&usp=drive_copy",
    },
    {
      name: "Lý Thuyết Điều Khiển Nâng cao",
      path: "https://drive.google.com/open?id=1uAsLcR-a37l5sUNhHQwrQbBWB84EKqD0&usp=drive_copy",
    },
    {
      name: "Kỹ thuật Robot",
      path: "https://drive.google.com/open?id=1H9nVRC2ldCLOhEyJb81DWNn4pBFxrhLc&usp=drive_copy",
    },
    {
      name: "Mạch điện tử thông tin",
      path: "https://drive.google.com/open?id=1toIH9hm3Ur8hcUsFVZ_ML6UvqhLrGhNI&usp=drive_copy",
    },
    {
      name: "Lý Thuyết Điều Khiển Nâng cao",
      path: "https://drive.google.com/open?id=1uAsLcR-a37l5sUNhHQwrQbBWB84EKqD0&usp=drive_copy",
    },
    {
      name: "Thủy khí",
      path: "https://drive.google.com/open?id=1ZYdO6wWVjYwTT1EupY4Q1IQPAtgF_z1s&usp=drive_copy",
    },
    {
      name: "Tín hiệu và hệ thông",
      path: "https://drive.google.com/open?id=1bpyQBFYDd47UmUjpk1oABit5yEe8Uj4T&usp=drive_copy",
    },
    {
      name: "Trí tuệ nhân tạo trong điều khiển",
      path: "https://drive.google.com/open?id=170nmTnWep8dPuAjDdBhD8Oqd_vCH9tBX&usp=drive_copy",
    },
    {
      name: "Xử lý số tín hiệu",
      path: "https://drive.google.com/open?id=1gC7KyYVr0BSBoiRYesgZaB7UwLOrcjYy&usp=drive_copy",
    },
    {
      name: "Xử lý ảnh",
      path: "https://drive.google.com/open?id=17szN-vmzzYYjp10NyGYmq_vg7NlO7YL0&usp=drive_copy",
    },
    {
      name: "Hệ thống cung cấp điện",
      path: "https://drive.google.com/open?id=1syMAe7wrt4a9CR7esh4OIgDv5mtD8-Qx&usp=drive_copy",
    },
    {
      name: "Máy điện",
      path: "https://drive.google.com/open?id=1qWW_LkaDuXlWXiADhGIyNc89yj8TZnRN&usp=drive_copy",
    },
    {
      name: "Giải tích hệ thống điện",
      path: "https://drive.google.com/open?id=1meCbb4KcLg60nkAHj-I-W2Kcak66dGb6&usp=drive_copy",
    },
    {
      name: "Thực tập điện 2",
      path: "https://drive.google.com/open?id=1iVp6t9TDB1pvujKdiFrm0qv0KflXv5cm&usp=drive_copy",
    },
    {
      name: "Nhà máy điện và trạm biến áp",
      path: "https://drive.google.com/open?id=1wvDn3mRGIQKIP-xoVRfgKXKctnlfhcCN&usp=drive_copy",
    },
    {
      name: "Hệ thống năng lượng xanh",
      path: "https://drive.google.com/open?id=1BAMU9ryQeUBgPiy52UpPDhM2tn8DnxKx&usp=drive_copy",
    },
  ];
  const xayDung = [
    {
      name: "Cơ lưu chất",
      path: "https://drive.google.com/open?id=1rRzUkXvFfJKUqfb7WeEVBB01UC9yZPB-&usp=drive_copy",
    },
    {
      name: "Dự toán xây dựng",
      path: "https://drive.google.com/open?id=1IcIys5kpXKb8Irurhjv9B4gW2XGreiBz&usp=drive_copy",
    },
    {
      name: "ETABS17",
      path: "https://drive.google.com/open?id=1i1j6tPDu9kxhcfzrArUr6MUexl6aEXsk&usp=drive_copy",
    },
    {
      name: "Hư hỏng sửa chữa công trình",
      path: "https://drive.google.com/open?id=1TTD2UZi9t7N2Kn-RR043wgdaC_F1ctSZ&usp=drive_copy",
    },
    {
      name: "Kết cấu bê tông cốt thép",
      path: "https://drive.google.com/open?id=1E4O3olR80G9iAVHB_zZY6ZQg5Zf6HtC1&usp=drive_copy",
    },
    {
      name: "Kỹ thuật thi công",
      path: "https://drive.google.com/open?id=17VdQYMHqldaH_gcxh5PTNgAMy0_-mmHf&usp=drive_copy",
    },
    {
      name: "Kết cấu thép",
      path: "https://drive.google.com/open?id=10_tBSaDVsJ3827tmoWrk_sIyRnEPtkfw&usp=drive_copy",
    },
    {
      name: "BÊ TÔNG CỐT THÉP",
      path: "https://drive.google.com/open?id=1e3VGLS9UrjPlz_TFCWoxtGEEY72YVZQ9&usp=drive_copy",
    },
    {
      name: "BTCT-2",
      path: "https://drive.google.com/open?id=1O5YV88vB1w7FoBlyXVGDnxT67zSmUDX2&usp=drive_copy  ",
    },
    {
      name: "CẤP THOÁT NƯƠC",
      path: "https://drive.google.com/open?id=1N8XGh4Mu_OJNLWTQymhuAlz_r7hV5Dwf&usp=drive_copy",
    },
    {
      name: "CƠ HỌC ĐẤT",
      path: "https://drive.google.com/open?id=1jNB4-UdItOVfGN0wuwwJyeF8TTPOE4vM&usp=drive_copy",
    },
    {
      name: "CƠ KẾT CẤU",
      path: "https://drive.google.com/open?id=1Vh6WGeCiTlUvECJZDr1lavnF5GZKAdQ6&usp=drive_copy",
    },
    {
      name: "ĐỊA CHẤT CÔNG TRÌNH",
      path: "https://drive.google.com/open?id=1I-m2xSdqZtmeN7gQR40r8Znb3nHEDjNe&usp=drive_copy",
    },
    {
      name: "KCCT THÉP - THÉP 2",
      path: "https://drive.google.com/open?id=1JQWcNZQALY2oNR0WCwQ3IqMDIUURYJEj&usp=drive_copy",
    },
    {
      name: "KẾT CẤU THÉP - THÉP 1",
      path: "https://drive.google.com/open?id=1nEB5d-jQ7zLz_58O3KakIyFFioTxfszS&usp=drive_copy",
    },
    {
      name: "KHOA HỌC TRÁI ĐÁT",
      path: "https://drive.google.com/open?id=1_fTSyD7lSH8HAVjmiHiaWsBEBCCHqdw6&usp=drive_copy",
    },
    {
      name: "KHỞI NGHIỆP",
      path: "https://drive.google.com/open?id=14bvY7YEEqzbi0Uh4-dIBGdzPj5uHqTq_&usp=drive_copy",
    },
    {
      name: "KINH TẾ HỌC ĐẠI CƯƠNG",
      path: "https://drive.google.com/open?id=1ik-vQTug6fJlF0ed5rNwcfcoMyo6u5L6&usp=drive_copy",
    },
    {
      name: "NỀN MÓNG",
      path: "https://drive.google.com/open?id=1V4RWPWMhooG226K5zwjl9ofr_ye-yAYn&usp=drive_copy",
    },
    {
      name: "QUẢN LÝ DA XÂY DỰNG",
      path: "https://drive.google.com/open?id=1R72U5Dm_nRxV0w7NmQhrQqzht3zK-t0e&usp=drive_copy",
    },
    {
      name: "SỨC BỀN",
      path: "https://drive.google.com/open?id=1sfSP740zev8otF-cA8v0ZFyOeWmOvWnK&usp=drive_copy",
    },
    {
      name: "THI NGHIỆM CÔNG TRÌNH",
      path: "https://drive.google.com/open?id=1-ZKjt7_IanoJ8_vlSRKH8uz1dSJwf7N3&usp=drive_copy",
    },
    {
      name: "TỔ CHỨC THI CÔNG",
      path: "https://drive.google.com/open?id=1aUEUKevSYcaqijhSiC1hO-9fYYNTeDNI&usp=drive_copy",
    },
    {
      name: "TRẮC ĐỊA ĐẠI CƯƠNG",
      path: "https://drive.google.com/open?id=1u9DQSvTS_zMt07iAEJy7GmFkbcAoUzcc&usp=drive_copy",
    },
    {
      name: "VẬT LIỆU XÂY DỰNG",
      path: "https://drive.google.com/open?id=1Mk0PF8BenxhMp0YUq99REkLhqdbEkZMP&usp=drive_copy",
    },
    {
      name: "Luận văn dân dụng mẫu",
      path: "https://drive.google.com/open?id=1egKL7e5fA2vpmCQjRpLVqEdd5s2wGpGk&usp=drive_copy",
    },

    {
      name: "SBVL  Bai giang  Luong Van Hai",
      path: "https://drive.google.com/open?id=1LgTrGM_ey3oH1ZkoQWRSXpSKhb8XygWQ&usp=drive_copy",
    },

    {
      name: "Tiếng Anh",
      path: "https://drive.google.com/open?id=1_RjeK4Gooq4_-kJ690HftjkJQYtUABmf&usp=drive_copy",
    },
    {
      name: "Bê tông cốt thép",
      path: "https://drive.google.com/open?id=1yozFS0k85Mc50Y44GsWVpsJc_B2EvCMm&usp=drive_copy",
    },
    {
      name: "Cấp thoát nước",
      path: "https://drive.google.com/open?id=1yozFS0k85Mc50Y44GsWVpsJc_B2EvCMm&usp=drive_copy",
    },
    {
      name: "Cơ học đất",
      path: "https://drive.google.com/open?id=1yozFS0k85Mc50Y44GsWVpsJc_B2EvCMm&usp=drive_copy",
    },
    {
      name: "Kỹ thuật xây dựng",
      path: "https://drive.google.com/open?id=1LACoA-rfyDbdp2MWI1mfdi-4Avvwc9YJ&usp=drive_copy",
    },
    {
      name: "Nền móng",
      path: "https://drive.google.com/open?id=1KvWfdy8lJmqgfVB0Oa7KPSiKAapfim6k&usp=drive_copy",
    },
    {
      name: "Cơ kết cấu",
      path: "https://drive.google.com/open?id=1WWxd2bjw-pmJGTdSW9GMyoGeSLVs7m9k&usp=drive_copy",
    },
    {
      name: "Bê tông cốt thép 1",
      path: "https://drive.google.com/drive/folders/1L0-GDiARJJBVhQHW1ftzD0jRwS8n090N?fbclid=IwAR1stTtl1PaQNQTbNMpw4CQEaVWusiBivPuUiaLAK_KxwtL3xBqTyTkUlXA",
    },
  ];
  const baoDuong = [
    {
      name: "Công nghệ cơ khí 1",
      path: "https://drive.google.com/drive/folders/1Iilks450PPNHO_MV7MhtvsqThhPox7B-?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
    },
    {
      name: "Công nghệ cơ khí 3",
      path: "https://drive.google.com/drive/folders/18cEJiyFeLmqrFoD3iHv14rELMERTYny4?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
    },
    {
      name: "Công nghệ điện",
      path: "https://drive.google.com/drive/folders/1gIl08k9a--M3esn_mQUJ9pSC6R7fPB_b?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
    },
    {
      name: "Cơ học vật liệu",
      path: "https://drive.google.com/drive/folders/1AYrwOVNsELpYl-GDaPd9fDqMtwaaV8rX?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
    },
    {
      name: "Kỹ thuật số",
      path: "https://drive.google.com/drive/folders/1GZAfdeKASqo6IdtTssayxLH-dehgpSS9?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
    },
    {
      name: "Công nghệ lạnh và điều hòa",
      path: "https://drive.google.com/open?id=18VE2GD9Qx5qcgDzY9Mot5WXJJL4XeRRr&usp=drive_copy",
    },
    {
      name: "Phương pháp tính và lập trình C - Thầy Nguyễn Trí Dũng",
      path: "https://drive.google.com/open?id=16gFbiuZOmsW1m_j3YjW73gsHxmotW0qR&usp=drive_copy",
    },
  ];
  const detmay = [];
  const mayTinh = [
    {
      name: "Cấu trúc dữ liệu và giải thuật - CO2003",
      path: "https://drive.google.com/open?id=1s_nSgbzJ6mWpXIMrLY7CxMObyeahFD3w&usp=drive_copy",
    },
    {
      name: "Database",
      path: "https://drive.google.com/open?id=1cGb90BKjnKYvXSwhUTCbFRZw2r-5sGtg&usp=drive_copy",
    },
    {
      name: "Công nghệ phần mềm",
      path: "https://drive.google.com/open?id=1rq649z-43yb3ycUiO0vVtvxDxy4_OiH4&usp=drive_copy",
    },

    {
      name: "Cấu trúc rời rạc - CO1007",
      path: "https://drive.google.com/open?id=15LCYyo9ZxG44koTDN2OqBUYOsRDQYeq8&usp=drive_copy",
    },
    {
      name: "Hệ điều hành - CO2017",
      path: "https://drive.google.com/open?id=1awD1vhghYq2Cb_k0mPfrnUJJXV7ydBb3&usp=drive_copy",
    },
    {
      name: "Hệ thống số - CO1023",
      path: "https://drive.google.com/open?id=1EOHfXQGRb3HwaBj0jFWQVI3LnINwZwG-&usp=drive_copy",
    },
    {
      name: "Kiến trúc máy tính - CO2007",
      path: "https://drive.google.com/open?id=1p_ITB2uzhGIjVmzRb9un36BITzDLIYHo&usp=drive_copy",
    },
    {
      name: "Kỹ thuật lập trình - CO1027",
      path: "https://drive.google.com/open?id=1LgQijKI-1PPspViVpSS8hm5rKLK1hhN4&usp=drive_copy",
    },
    {
      name: "Lập trình nâng cao - CO2039",
      path: "https://drive.google.com/open?id=1ZKe7rIKxy50m9uCXxxeg7ElvLk8q0yN7&usp=drive_copy",
    },
    {
      name: "Mô hình hóa - CO2011",
      path: "https://drive.google.com/open?id=1HnVQiQG963izS6I_SXlhEEgYTYBsvYls&usp=drive_copy",
    },
    {
      name: "Nhập Môn Điện Toán - CO1005",
      path: "https://drive.google.com/open?id=1Kz1wKkWutlLRGLbP1z61Mg1f_h6Ai4p6&usp=drive_copy",
    },
  ];
  const congnghevatlieu = [
    {
      name: "Cơ sở khoa học vật liệu",
      path: "https://drive.google.com/open?id=1aCb_-8yXVhCEvULqBWmNWNOugNNIqxCP&usp=drive_copy",
    },
    {
      name: "Hóa lý - Công nghệ vật liệu",
      path: "https://drive.google.com/open?id=1aCb_-8yXVhCEvULqBWmNWNOugNNIqxCP&usp=drive_copy",
    },
    {
      name: "Hóa phân tích",
      path: "https://drive.google.com/open?id=1aCb_-8yXVhCEvULqBWmNWNOugNNIqxCP&usp=drive_copy",
    },
    {
      name: "Hóa vô cơ - Công nghệ vật liệu",
      path: "https://drive.google.com/open?id=1aCb_-8yXVhCEvULqBWmNWNOugNNIqxCP&usp=drive_copy",
    },
    {
      name: "Vật lý chất rắn",
      path: "https://drive.google.com/open?id=1aCb_-8yXVhCEvULqBWmNWNOugNNIqxCP&usp=drive_copy",
    },
    {
      name: "Vẽ kỹ thuật",
      path: "https://drive.google.com/open?id=1aCb_-8yXVhCEvULqBWmNWNOugNNIqxCP&usp=drive_copy",
    },
  ];
  const khoahoa = [
    {
      name: "Các Phương Pháp Phân Tích Hiện Đại",
      path: "https://drive.google.com/open?id=1BBBatvCf3-kr5eOn6aptl9Y55cl-KNa0&usp=drive_copy",
    },
    {
      name: "Cơ sở điều khiển quá trình",
      path: "https://drive.google.com/open?id=1GKdEoeu_U90DKfgWVwQ8mFcLwsYqL9bM&usp=drive_copy",
    },
    {
      name: "Cơ Sở Vật Liệu Và Bảo Vệ Ăn Mòn",
      path: "https://drive.google.com/open?id=15hkdzLfRZFnlfkJ2_KXM7O1ppHYc2onG&usp=drive_copy",
    },

    {
      name: "Hóa Hữu Cơ",
      path: "https://drive.google.com/open?id=1SEUGCI-wTIdOmgpq1MuItWbGo0qaHh2h&usp=drive_copy",
    },
    {
      name: "Hóa Lí 1",
      path: "https://drive.google.com/open?id=19fmnDjNlSoVm9ZP_OVoAOD3nvowClZjV&usp=drive_copy",
    },
    {
      name: "Hóa Lí 2",
      path: "https://drive.google.com/open?id=1ZL2wbymOvbmIEQxSgXOf4y6wMObLjSEH&usp=drive_copy",
    },
    {
      name: "Hóa Phân Tích",
      path: "https://drive.google.com/open?id=1hXqkiUYLpo8zSUcDlbXd3Avnrf_dkitU&usp=drive_copy",
    },
    {
      name: "Hóa Vô Cơ",
      path: "https://drive.google.com/open?id=1JSUkGChNJXJ73Ut8FcEMr9meqJtbasQ4&usp=drive_copy",
    },
    {
      name: "Thí Nghiệm Hóa Hữu Cơ",
      path: "https://drive.google.com/open?id=1-U8FJFDJbPNvBOFFKlTqMfoUi5PZVEKA&usp=drive_copy",
    },
    {
      name: "Thí Nghiệm Hóa Lý",
      path: "https://drive.google.com/open?id=1aqVA5B5WHq_YQHTb4qQMPC24AsCphT6h&usp=drive_copy",
    },
    {
      name: "Thí Nghiệm Hóa Phân Tích",
      path: "https://drive.google.com/open?id=1ELImEcZb-ZbEozK9CBFb985rv_226O-d&usp=drive_copy",
    },
    {
      name: "Thí Nghiệm Hóa Vô Cơ",
      path: "https://drive.google.com/open?id=1CtFk7du9G5PnFeaWUMNgxKXw5j83faMO&usp=drive_copy",
    },
    {
      name: "Truyền Khối",
      path: "https://drive.google.com/open?id=1b5LhSdQ5Ma9HyVOMPN_LUH7VZxHrKFaf&usp=drive_copy",
    },
    {
      name: "Truyền Nhiệt",
      path: "https://drive.google.com/open?id=1AcRIHS0HtPImxKna-YmhZF3-G6B8rLTZ&usp=drive_copy",
    },
    {
      name: "XÚC TÁC",
      path: "https://drive.google.com/open?id=1WFXJFSiQA9cmY9qcElT7WMTT00vF4Gy4&usp=drive_copy",
    },
  ];
  const diachatdaukhi = [
    {
      name: "Qúa trình thiết bị và công nghệ 1",
      path: "https://drive.google.com/open?id=1d1U_1BnRTSdD8kPbnCDabpYVH8Hdf99U&usp=drive_copy",
    },
    {
      name: "An toàn và Bảo vệ mội trường trong Công nghệ Dầu Khí",
      path: "https://drive.google.com/open?id=1i5lW3clP-5zvM8vLoen2Oem5c9x4Z54T&usp=drive_copy",
    },
    {
      name: "Bảo vệ môi trường trong công nghiệp dầu khí + kiến tập",
      path: "https://drive.google.com/open?id=1-xJ4MkKWERvzkHVa3p_lOkJ0Nn8oassl&usp=drive_copy",
    },
    {
      name: "Công trình xây dựng",
      path: "https://drive.google.com/open?id=1zVKl-XUbq4EreAcvZBJqaRFRd075x4kR&usp=drive_copy",
    },
    {
      name: "Cơ học đá _ Thực hành",
      path: "https://drive.google.com/open?id=1iw7A1Z5ZxG1B23BbZ_rwLYD5JUAUgRiy&usp=drive_copy",
    },
    {
      name: "Cơ sở thủy địa cơ học",
      path: "https://drive.google.com/open?id=1J8Hfn3sTG1mJKqErEinpZIL5gxhlN62L&usp=drive_copy",
    },
    {
      name: "Địa kỹ thuật 1",
      path: "https://drive.google.com/open?id=1sZ4ioRrBa3fnnB9f3ad1sa0qL16T5GW0&usp=drive_copy",
    },
    {
      name: "Khai thác và Bảo vệ tài nguyên Nước dưới đất",
      path: "https://drive.google.com/open?id=1jSjuEScRB4a_WfYiNsJ9Ip5c2heNDvGF&usp=drive_copy",
    },
    {
      name: "",
      path: "",
    },
    {
      name: "Đồ án tính toán ứng dụng",
      path: "https://drive.google.com/open?id=1hAIaMJw-vGBxyPNAEA8kTqeb4crCs9OE&usp=drive_copy",
    },
    {
      name: "Cơ sở khoa học dầu khí",
      path: "https://drive.google.com/open?id=1gKl8f5D3eiTJhTCd4oDKwHN7jwB-0WFN&usp=drive_copy",
    },
    {
      name: "Tinh thể khoáng vật _ thạch học",
      path: "https://drive.google.com/open?id=1-NgKL67lVh-4Og8p97jYJh1R1mSIeIwe&usp=drive_copy",
    },
    {
      name: "Địa kiến trúc đo vẽ bản đồ",
      path: "https://drive.google.com/open?id=1ofCxyMZoGQzmCuoFXoWI6Ynaa_jDunth&usp=drive_copy",
    },
    {
      name: "Địa vật lý dầu khí",
      path: "https://drive.google.com/open?id=1KAZTuf3YxLM1Qc_Q7siSE4dhkUjSNQBb&usp=drive_copy",
    },
    {
      name: "Địa tin học đại cương",
      path: "https://drive.google.com/open?id=1UgsbDUjiu4oAhtWoX_UyWtcAXxUNKh29&usp=drive_copy",
    },
    {
      name: "Công nghệ khoan khai thác dầu khí",
      path: "https://drive.google.com/open?id=1GLRff9yAhjyY3D6ALP6nhoBLmLgfu-6j&usp=drive_copy",
    },
    {
      name: "Kỹ thuật vỉa _ dầu khí",
      path: "https://drive.google.com/open?id=1Ba3rZjYXqo3Ivj4LnQcaWdK_NiEKK_k2&usp=drive_copy",
    },
    {
      name: "Đặc trưng hóa và mô hình hóa vỉa",
      path: "https://drive.google.com/open?id=1vbw-lDKsEyhH1A0_1MrZMAg9CIfvf3wv&usp=drive_copy",
    },
    {
      name: "ĐỊA THỐNG KÊ",
      path: "https://drive.google.com/open?id=1oSDhCX6o0SksVwxkZx9yxs9FNB11Y68G&usp=drive_copy",
    },
    {
      name: "Đồ án kỹ thuật dầu khí 1",
      path: "https://drive.google.com/open?id=1Pjf2keJy1Ky6JF55ar_0vyv17P4g4Pf_&usp=drive_copy",
    },
    {
      name: "ge3159 đồ án kỹ thuật dầu khí 2",
      path: "https://drive.google.com/open?id=10WChASuCpwP2sqQs6frrAiePf3OdMNO2&usp=drive_copy",
    },
    {
      name: "Tầng chứa, tài nguyên và trữ lượng dầu",
      path: "https://drive.google.com/open?id=1TlI2ELWbHo4CIqxqZ9EOJXEe5mp4_oTc&usp=drive_copy",
    },
    {
      name: "Công nghệ xử lý dầu",
      path: "https://drive.google.com/open?id=1o8LYIqZ4okRUnVFWZsUe-KyNI_HsFBU7&usp=drive_copy",
    },
    {
      name: "HOÀN THIỆN GIẾNG VÀ KÍCH THƯỚC VỈA",
      path: "https://drive.google.com/open?id=1808trEZbBQmL10cGHkcNYFS76QjoLRAc&usp=drive_copy",
    },
    {
      name: "CƠ HỌC ĐÁ DẦU KHÍ",
      path: "https://drive.google.com/open?id=1LbEzskYKCcAu2uB95R7ZzMmtNc6oiNJx&usp=drive_copy",
    },
    {
      name: "CONG NGHE CHE BIEN DAU KHI",
      path: "https://drive.google.com/open?id=12fNacoR5oEqBMjq1s8hgaV_felIgG8M4&usp=drive_copy",
    },
    {
      name: "Thực tập ngoài trường_",
      path: "https://drive.google.com/open?id=1Awh_I7Bzt92ob-kfAG4gXGpA5DtQMRx4&usp=drive_copy",
    },
    {
      name: "Luận Văn",
      path: "https://drive.google.com/open?id=1KoBrmNdX7JiNx3VVdzx4A0uJYE0ikcXG&usp=drive_copy",
    },
  ];
  const hangkhong = [
    {
      name: "Cơ học thủy khí",
      path: "https://drive.google.com/open?id=1mXmv86ObpxB8Yzai6ySCC-ZIj5tOALTO&usp=drive_copy",
    },
    {
      name: "Cơ học vật liệu hàng không",
      path: "https://drive.google.com/open?id=1c0lsoAcXnkvNBlQdAdprYQ4u4qXRuVb1&usp=drive_copy",
    },
    {
      name: "Cơ kết cấu giao thông",
      path: "https://drive.google.com/open?id=1zDuiHu4q7m8Q6lkRU0eOuRq_MScw1cWs&usp=drive_copy",
    },
    {
      name: "Kỹ thuật chế tạo",
      path: "https://drive.google.com/open?id=1yugdL3St4dkoH0gayeM2wCnCfnBlTfql&usp=drive_copy",
    },
  ];
  const moitruong = [
    {
      name: " QUÁ TRÌNH SINH HỌC ",
      path: "https://drive.google.com/open?id=1v4kmDtwqLh8L_Oz5Br-l2Bt669-nlOtL&usp=drive_copy",
    },
    {
      name: "XỬ LÝ NƯỚC CẤP ",
      path: "https://drive.google.com/open?id=1n4l0YaBozUiazL0DXcMLojPd9bXk5zJx&usp=drive_copy",
    },
    {
      name: "XỬ LÝ NƯỚC THẢI ",
      path: "https://drive.google.com/open?id=1kRVNm05UcPns-eTy7E6Vbum_V8pzWss9&usp=drive_copy",
    },
    {
      name: "Tập ghi chép bài học môn HSE",
      path: "https://drive.google.com/open?id=1gz5lpvOehWNwcr1Gk_IPCsP283E2qyMv&usp=drive_copy",
    },
  ];
  const cokhi = [
    {
      name: "CAD-CAE",
      path: "https://drive.google.com/open?id=18EpTiHSsNZMPPUoOPmGQPruNIMvHUelk&usp=drive_copy",
    },
    {
      name: "CAD-CAM",
      path: "https://drive.google.com/open?id=1PUqio2-uxFSFeasStiPSSoWJcZfzriwJ&usp=drive_copy",
    },
    {
      name: "ĐỒ ÁN CHUYÊN NGÀNH",
      path: "https://drive.google.com/open?id=18fI5wk9-Fim_cipeTCQBXVN9neKJcI-i&usp=drive_copy",
    },
    {
      name: "Đồ án thiết kế hộp giảm tốc",
      path: "https://drive.google.com/open?id=1H9Xnd0IpaGUpsBc-hvZAn-OL8wm97skD&usp=drive_copy",
    },
    {
      name: "Công nghệ may 1",
      path: "https://drive.google.com/open?id=1Q2Qof0mhESyhSXzo9XrOG08mttoQ0MO2&usp=drive_copy",
    },
    {
      name: "Công nghệ may 2",
      path: "https://drive.google.com/open?id=1jw46AnrSf17WPoIeuxvgxdjtKvmQ5-Bx&usp=drive_copy",
    },
    {
      name: "Cơ học máy",
      path: "https://drive.google.com/open?id=16yOcbZTd2HVY7mGqZSH-a9_0Wl6UOzcU&usp=drive_copy",
    },
    {
      name: "Khoa học vật liệu dệt",
      path: "https://drive.google.com/open?id=1PFsLE_mb1PzMa3Xs55sxbedTCc1foNkj&usp=drive_copy",
    },
    {
      name: "Mỹ thuật trang phục",
      path: "https://drive.google.com/open?id=179qlZv9QXtftGtTiLQ9LFcvqCFnp7__b&usp=drive_copy",
    },
    {
      name: "Thí nghiệm kiểm tra phân tích vật liệu dệt",
      path: "https://drive.google.com/open?id=1_cV5BbEYeKGenQtHvFTuq3s9T0zjNnEr&usp=drive_copy",
    },
    {
      name: "Kĩ thuật chế tạo 1",
      path: "https://drive.google.com/open?id=1swziShCoyM0-6SlC-tCV5IUVz24XHyaM&usp=drive_copy",
    },
    {
      name: "Kĩ thuật chế tạo 2",
      path: "https://drive.google.com/open?id=1WwOi837LG4xtZ2js31uGRqAM61ypgvCT&usp=drive_copy",
    },
    {
      name: "Kĩ thuật chế tạo 3",
      path: "https://drive.google.com/open?id=1dhG2RHLn4QhJ9frE9WHyTfNp8R8qXxc3&usp=drive_copy",
    },
    {
      name: "Quản lý sản xuất",
      path: "https://drive.google.com/open?id=1-swY-PxmnrwO2rAHs9ftYHjZOZLb4yz1&usp=drive_copy",
    },
    {
      name: "Thủy lực và Khí Nén",
      path: "https://drive.google.com/open?id=1-1p2iRSBVjF0CXumNErzjGDY1lXn7pkA&usp=drive_copy",
    },
    {
      name: "Vẽ Cơ Khí",
      path: "https://drive.google.com/open?id=12055SlvWTPhQRQXNSmqP_nE6zZZkcJ_r&usp=drive_copy",
    },
  ];

  const comming = [
    {
      name: "Đang cập nhật nhé.......",
      path: "",
    },
  ];
  const DataFreedom = [
    {
      name: "Khởi nghiệp",
      path: "https://drive.google.com/open?id=1-QEAI5u21Io_1z2erVmAkwtwiwdsKCEp&usp=drive_copy",
    },
    {
      name: "Kinh tế học",
      path: "https://drive.google.com/open?id=14UTIX4V4X3HXIUm334wr6KwXQoqPqu45&usp=drive_copy",
    },
    {
      name: "Sinh học đại cương",
      path: "https://drive.google.com/open?id=1JKlw5XdlBvjvhi9Tb9xt4S31hJKmqWWx&usp=drive_copy",
    },
    {
      name: "Vi sinh vật đại cương",
      path: "https://drive.google.com/open?id=1Z8607a6gDkSo4WUdi91HMsjGB0_F4KVD&usp=drive_copy",
    },
    {
      name: "Con người môi trường",
      path: "https://drive.google.com/open?id=1OjuwC3vM6dMqRnWcn6EKAxcnqmII8VBM&usp=drive_copy",
    },
    {
      name: "Cong người và môi trường",
      path: "https://drive.google.com/open?id=1GtA0PXC5Iv5UPlHTAwjOJR9KJX5w2Paq&usp=drive_copy",
    },
    {
      name: "Kinh tế học đại cương",
      path: "https://drive.google.com/open?id=1KI-2V-cndhETcv5CvmEz2E2TmfvJOyRS&usp=drive_copy",
    },
    {
      name: "Kinh tế kỹ thuật",
      path: "https://drive.google.com/open?id=1te3OFlN07ruJqxVOFQTJQlCMN7j_pTZ_&usp=drive_copy",
    },
    {
      name: "Quản lý dự án",
      path: "https://drive.google.com/open?id=1Y-1czWz6Dp5tc4xrkPNPzc88dHVqgTpp&usp=drive_copy",
    },
    {
      name: "Quản lý sản suất",
      path: "https://drive.google.com/open?id=1qzZTXLp1hzmT_HOag8QpYTdK_N8xdYvO&usp=drive_copy",
    },
  ];
  const DataSpecialized = [
    {
      specializedId: "cse",
      specializedName: "Khoa Máy Tính",
      program:
        "https://drive.google.com/drive/folders/1xqxT-SckxRioM7sc_EsYFzafduT3U0Mw?usp=sharing",
      DataCourseBasic,
      DataCourseSociety,
      specializedCourse: mayTinh,
    },
    {
      specializedId: "bdcn",
      specializedName: "Khoa Bảo Dưỡng Công Nghiệp",
      DataCourseBasic,
      DataCourseSociety,
      program:
        "https://drive.google.com/drive/folders/1j5vAWLGX9DBSfkFdU2kE_x9xuqmreCpI?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      specializedCourse: baoDuong,
    },
    {
      specializedId: "ck",
      specializedName: "Khoa Cơ Khí",
      DataCourseBasic,
      DataCourseSociety,
      program:
        "https://drive.google.com/drive/folders/1QBcnOKp1IANpFc3ZxVsZusAuaEfaLnF0?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      specializedCourse: cokhi,
    },
    {
      specializedId: "dcvdk",
      specializedName: "Khoa Kỹ Thuật Địa Chất Và Dầu Khí",
      DataCourseBasic,
      DataCourseSociety,
      program:
        "https://drive.google.com/drive/folders/1F3MEuGi3m4y04JIoorZIR0M0Kl3non2L?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      specializedCourse: diachatdaukhi,
    },
    {
      specializedId: "ddt",
      specializedName: "Khoa Điện Điện Tử",
      DataCourseBasic,
      DataCourseSociety,
      program:
        "https://drive.google.com/drive/folders/1jB5y6j1qlB_7pzgwr3JQs6uvOLt7FhWr?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      specializedCourse: DataCourseAdvance,
    },
    {
      specializedId: "ktgt",
      specializedName: "Khoa Kỹ Thuật Giao Thông",
      DataCourseBasic,
      DataCourseSociety,
      program:
        "https://drive.google.com/drive/folders/18OaeiKo_FPZ-azvG3A3FOvLp0gL1yAo6?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      specializedCourse: hangkhong,
    },
    {
      specializedId: "kthh",
      specializedName: "Khoa Kỹ Thuật Hóa Học",
      program:
        "https://drive.google.com/drive/folders/1s9XjlVpqi3U04hqEvOfyJzdp9oKpQt3X?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      DataCourseBasic,
      DataCourseSociety,
      specializedCourse: khoahoa,
    },
    {
      specializedId: "mtvtn",
      specializedName: "Khoa Môi Trường Và Tài Nguyên",
      program:
        "https://drive.google.com/drive/folders/1GKIhihYL4rZI_vKIz7ecTIO2b3iv0oCY?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      DataCourseBasic,
      DataCourseSociety,
      specializedCourse: moitruong,
    },
    {
      specializedId: "qlcn",
      specializedName: "Khoa Quản Lý Công Nghiệp",
      program:
        "https://drive.google.com/drive/folders/1NysLFzZlzyLK3TW4XDFHjrO7JznzebbT?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      DataCourseBasic,
      DataCourseSociety,
      specializedCourse: comming,
    },
    {
      specializedId: "khud",
      specializedName: "Khoa Khoa Học Ứng Dụng",
      program:
        "https://drive.google.com/drive/folders/15dEO9tnXDABdkGgYk0T4IjIt6Ua5minT?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      DataCourseBasic,
      DataCourseSociety,
      specializedCourse: comming,
    },

    {
      specializedId: "cnvl",
      specializedName: "Khoa Công Nghệ Vật Liệu",
      DataCourseBasic,
      DataCourseSociety,
      program:
        "https://drive.google.com/drive/folders/1kpRciYTN-yiSaTZCAvTazRzo7Ke_rKL3?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      specializedCourse: congnghevatlieu,
    },
    {
      specializedId: "xd",
      specializedName: "Khoa Xây Dựng",
      program:
        "https://drive.google.com/drive/folders/1jbCIPj3bEgUqndkW3E9-6J_qACJZJYEe?fbclid=IwAR37eSk-9wAVHK1j1d7GUFYOIHc-fdQeisap7vY8eduPCIuW9pjYdhcsyj4",
      DataCourseBasic,
      DataCourseSociety,
      specializedCourse: xayDung,
    },
  ];

  const [statusCourseBasic, setStatusCourseBasic] = useState(true);
  const [statusCourseAdvance, setStatusCourseAdvance] = useState(false);
  const [statusCourseSociety, setStatusCourseSociety] = useState(false);
  const [statusCourseFreedom, setStatusCourseFreedom] = useState(false);
  const { id } = useParams();
  const [specialized, setSpecialized] = useState(null);
  useEffect(() => {
    const result = DataSpecialized.find((item) => {
      return item.specializedId === id;
    });
    document.title = "Tài liệu " + result.specializedName.toLowerCase();
    setSpecialized(result);
  }, []);

  return (
    <div className={cx("container")}>
      <div>
        <div className={cx("intro")}>
          <h1>Tài Liệu {specialized?.specializedName || "máy tính"}</h1>
          <span>
            Chia sẻ, cung cấp miễn phí tài liệu đầy đủ và mới nhất của{" "}
            {specialized?.specializedName || "máy tính"} đại học Bách Khoa HCM,
            chúc bạn học tốt nha.
          </span>
          <div>
            <h2>Chất lượng của tài liệu</h2>
            <div className={cx("benefits")}>
              <div className={cx("benefits-list")}>
                <div>
                  <FontAwesomeIcon
                    fontSize="1.4rem"
                    icon={faCheck}
                  ></FontAwesomeIcon>
                  <span>Đầy đủ, cập nhật mới nhất</span>
                </div>
                <div>
                  <FontAwesomeIcon
                    fontSize="1.4rem"
                    icon={faCheck}
                  ></FontAwesomeIcon>
                  <span>Đủ các môn đại cương, chuyên nghành khoa</span>
                </div>
              </div>
              <div className={cx("benefits-list")}>
                <div>
                  <FontAwesomeIcon
                    fontSize="1.4rem"
                    icon={faCheck}
                  ></FontAwesomeIcon>
                  <span>Lý thuyết, đề thi, bài giảng, bài tập lớn</span>
                </div>
                <div>
                  <FontAwesomeIcon
                    fontSize="1.4rem"
                    icon={faCheck}
                  ></FontAwesomeIcon>
                  <span>Sẽ được cập nhật tài liệu hàng tuần</span>
                </div>
              </div>
            </div>
            <div className={cx("advertisement-mobile")}>
              <div className={cx("container-advertisement")}>
                <div className={cx("container-image")}>
                  <img className={cx("image")} src={subject}></img>
                  <Button link={"/"} text={"Danh sách"}></Button>
                </div>

                <div className={cx("detail-advertisement")}>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faUser}
                    ></FontAwesomeIcon>
                    <span>Sinh viên Bách Khoa</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faFacebook}
                    ></FontAwesomeIcon>
                    <span>Học Livestream</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faWordpress}
                    ></FontAwesomeIcon>
                    <span>Hai buổi/tuần học hơn 3 tháng</span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faTimeline}
                    ></FontAwesomeIcon>
                    <span>Bận có thể xem lại video </span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#494949"
                      icon={faCameraRetro}
                    ></FontAwesomeIcon>
                    <span>Hỗ trợ bài tập tớp các môn</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={cx("document")}>
              <div>
                <h2>Tài liệu các môn</h2>
              </div>
              <div>
                <div className={cx("program")}>
                  <span>
                    Mỗi kì thì bạn sẽ học tầm 4-5 môn, chi tiết bạn xem ở chương
                    trình đào tạo.
                  </span>
                  <a target="_blank" href={specialized?.program}>
                    Xem chương trình đào tạo
                  </a>
                </div>
                <div
                  onClick={() => {
                    setStatusCourseBasic((state) => {
                      return !state;
                    });
                  }}
                  className={cx("basic-course")}
                >
                  <div>
                    <FontAwesomeIcon
                      color="#f05123"
                      fontSize="1.4rem"
                      icon={faMinus}
                    ></FontAwesomeIcon>
                    <span>1. Các môn học đại cương năm nhất, năm 2</span>
                  </div>
                  <span>{DataCourseBasic.length} môn</span>
                </div>
                {statusCourseBasic && (
                  <div>
                    {DataCourseBasic?.map((course) => {
                      return (
                        <Subject
                          active
                          name={course.name}
                          weight={"15kb"}
                          path={course.path}
                        ></Subject>
                      );
                    })}
                  </div>
                )}

                <div
                  className={cx("basic-course")}
                  onClick={() => {
                    setStatusCourseSociety((state) => {
                      return !state;
                    });
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      fontSize="1.4rem"
                      color="#f05123"
                      icon={faMinus}
                    ></FontAwesomeIcon>
                    <span>2. Các môn chính trị, xã hội </span>
                  </div>
                  <span>{DataCourseSociety.length} môn</span>
                </div>
                <div>
                  {statusCourseSociety && (
                    <div>
                      {DataCourseSociety?.map((course) => {
                        return (
                          <Subject
                            active
                            name={course.name}
                            weight={"15kb"}
                            path={course.path}
                          ></Subject>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div
                  onClick={() => {
                    setStatusCourseAdvance((state) => {
                      return !state;
                    });
                  }}
                  className={cx("basic-course")}
                >
                  <div>
                    <FontAwesomeIcon
                      color="#f05123"
                      fontSize="1.4rem"
                      icon={faMinus}
                    ></FontAwesomeIcon>
                    <span>3. Các môn chuyên ngành, cơ sở ngành</span>
                  </div>
                  <span>{specialized?.specializedCourse?.length} môn</span>
                </div>
                {statusCourseAdvance && (
                  <div>
                    {specialized?.specializedCourse?.map((course) => {
                      return (
                        <Subject
                          active
                          name={course.name}
                          weight={"15kb"}
                          path={course.path}
                        ></Subject>
                      );
                    })}
                  </div>
                )}
                <div
                  onClick={() => {
                    setStatusCourseFreedom((state) => {
                      return !state;
                    });
                  }}
                  className={cx("basic-course")}
                >
                  <div>
                    <FontAwesomeIcon
                      color="#f05123"
                      fontSize="1.4rem"
                      icon={faMinus}
                    ></FontAwesomeIcon>
                    <span>4. Các tự chọn A,B và khác</span>
                  </div>
                  <span>{DataCourseAdvance.length} môn</span>
                </div>
                {statusCourseFreedom && (
                  <div>
                    {DataFreedom?.map((course) => {
                      return (
                        <Subject
                          active
                          name={course.name}
                          weight={"15kb"}
                          path={course.path}
                        ></Subject>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={cx("content")}></div>
      </div>
      <div className={cx("advertisement-desktop")}>
        <div className={cx("link-intro")}>
          <h3>CÁC CHỦ ĐỀ ĐÁNG CHÚ Ý</h3>
          <div>
            <Button pt5 text={"Thông Lớp Đại Cương"}></Button>
            <Button
              link={"https://zalo.me/g/sqzmqu522"}
              blank
              pt5
              text={"Nhóm Zalo"}
            ></Button>
            <Button pt5 text={"Tài Liệu Các Khoa"}></Button>
            <Button pt5 text={"Video Hay"}></Button>
          </div>
        </div>
        <div className={cx("container-advertisement")}>
          <div className={cx("container-image")}>
            <img className={cx("image")} src={subject}></img>
          </div>
          <h5>Chất Lượng</h5>
          <Button link={"/"} text={"Danh sách"}></Button>
          <div className={cx("detail-advertisement")}>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faUser}
              ></FontAwesomeIcon>
              <span>Sinh viên Bách Khoa</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faFacebook}
              ></FontAwesomeIcon>
              <span>Học Livestream</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faWordpress}
              ></FontAwesomeIcon>
              <span>Hai buổi/tuần học hơn 3 tháng</span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faTimeline}
              ></FontAwesomeIcon>
              <span>Bận có thể xem lại video </span>
            </div>
            <div>
              <FontAwesomeIcon
                fontSize="1.4rem"
                color="#494949"
                icon={faCameraRetro}
              ></FontAwesomeIcon>
              <span>Hỗ trợ bài tập tớp các môn</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Learning;
