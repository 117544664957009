import { useEffect, useState } from "react";
import Header from "../../Elements/Header/Header";
import Slidebar from "../../Elements/Slidebar/Slidebar";
import classNames from "classnames/bind";
import styles from "./DefaultLayout.module.scss";
import SlidebarMobile from "../../Elements/SlidebarMobile/SlidebarMobile";
import Footer from "../../Elements/Footer/Footer";
import ChatAI from "../../pages/ChatAI/ChatAI";
import Notification from "../../Elements/Notification/Notification";
const cx = classNames.bind(styles);
function DefaultLayout({ children }) {
  const [statusSlidebar, setStatusSlidebar] = useState(false);
  const [animation, setAnimation] = useState(true);
  useEffect(() => {
    const idTimer = setInterval(() => {
      setAnimation((pre) => {
        return !pre;
      });
    }, 10000);
    return () => {
      clearInterval(idTimer);
    };
  }, []);

  return (
    <div>
      <Header onclick={setStatusSlidebar}></Header>
      <div className={cx("containerx")}>
        {statusSlidebar || <Slidebar></Slidebar>}
        <SlidebarMobile
          active={statusSlidebar}
          onclick={setStatusSlidebar}
        ></SlidebarMobile>

        <div className={cx("page")}>{children}</div>
        <div className={cx("notify", { displayNotify: true })}>
          <Notification animation={animation}></Notification>
        </div>
      </div>
      <Footer></Footer>
      <ChatAI></ChatAI>
    </div>
  );
}

export default DefaultLayout;
