import classNames from "classnames/bind";
import Tippy from "@tippyjs/react/headless";
import { useEffect, useState } from "react";
import { faBars, faBell } from "@fortawesome/free-solid-svg-icons";
import "tippy.js/dist/tippy.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Header.module.scss";
import logo from "../../../assets/images/logo.png";
import bk from "../../../assets/images/BK.png";
import flag from "../../../assets/images/vietnam.png";
import xstk from "../../../assets/images/Course/xacsuatthongke.png";
import docker from "../../../assets/images/CourseFree/docker.png";
import Search from "../Search";
import Wrapper from "../Popper/Wrapper";
import ItemSearch from "../Search/ItemSearch/ItemSearch";

const cx = classNames.bind(styles);
function Header({ onclick }) {
  const DataSearch = [
    {
      header: "Mới",
      Path: "/",
      data: [
        { name: "Lớp Xác Suất Thống Kê", path: "/course/xstk", image: xstk },
        { name: "Khóa học miễn phí", path: "/document/300", image: docker },
      ],
    },
  ];
  const [searchResult, setSearchResult] = useState([]);
  const [show, setShow] = useState(false);
  const handleOutside = () => {
    setShow(false);
  };

  useEffect(() => {
    setSearchResult(DataSearch);
  }, []);
  return (
    <header className={cx("container")}>
      <a href="/" className={cx("logo")}>
        <div>
          <img className={cx("image-logo")} src={logo}></img>
        </div>
        <h4 className={cx("group-title")}>BÁCH KHOA-CNCP</h4>
      </a>
      <div
        onClick={() => {
          onclick(true);
        }}
        className={cx("menu")}
      >
        <FontAwesomeIcon
          fontSize="2rem"
          color="#7c7c7c"
          icon={faBars}
        ></FontAwesomeIcon>
      </div>
      <div className={cx("search")}>
        <Search></Search>
      </div>

      <div className={cx("detail")}>
        <div className={cx("language")}>
          <img src={flag}></img>
          <span>Tiếng Việt</span>
        </div>
        <div className={cx("infor")}>
          <div
            className={cx("bell")}
            onClick={() => {
              setShow(true);
            }}
          >
            <Tippy
              visible={show}
              onClickOutside={handleOutside}
              interactive={true}
              delay={[0, 200]}
              render={(attrs) => (
                <div className={cx("box")} tabIndex="-1" {...attrs}>
                  <Wrapper infor={true}>
                    <span className={cx("infor")}>Thông báo</span>
                    {searchResult?.map((item) => {
                      return (
                        <ItemSearch
                          infor={true}
                          link={item.Path}
                          title={item.header}
                          data={item.data}
                        ></ItemSearch>
                      );
                    })}
                    <a
                      className={cx("link_all")}
                      href="/"
                      style={{
                        fontSize: 14,
                        paddingTop: 10,
                        paddingLeft: 10,
                        paddingBottom: 10,
                      }}
                    >
                      View all......
                    </a>
                  </Wrapper>
                </div>
              )}
            >
              <a>
                <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
              </a>
            </Tippy>
          </div>

          <img src={bk}></img>
        </div>
        {/* <Button text={"Đăng nhập"}></Button> */}
      </div>
    </header>
  );
}

export default Header;
